import { Component, EventEmitter } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'frontend-custom-modal',
  templateUrl: './custom-modal.component.html',
  styleUrls: ['./custom-modal.component.scss'],
})
export class CustomModalComponent {
  title: string;
  headerTitle: string;
  headerSubTitle: string;
  showImage = true;
  image = 'assets/images/confirm-background.png';
  confirmText: string;
  closeText: string;
  customClass: string;
  emitOnClose = false;

  public closeEvent: EventEmitter<any> = new EventEmitter();

  constructor(
    public bsModalRef: BsModalRef,
    private translateService: TranslateService
  ) {
    this.title = this.translateService.instant('confirmation');
    this.confirmText = this.translateService.instant('confirm');
    this.closeText = this.translateService.instant('cancel');
  }

  submitForm(event = true) {
    this.closeEvent.emit(event);
    this.bsModalRef.hide();
  }
}
