export const SocialMedias = [
  {
    name: 'Instagram',
    svg: `<svg width="24" height="24" viewBox="0 0 24 24" fill="none"
         xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd"
              d="M17.5034 0H6.4966C2.9143 0 0 2.9143 0 6.4966V17.5036C0 21.0857 2.9143 24 6.4966 24H17.5036C21.0857 24 24 21.0857 24 17.5036V6.4966C24 2.9143 21.0857 0 17.5034 0ZM22 17.5036C22 20.3098 20.31 22 17.5036 22H6.5C3.6935 22 2 20.3062 2 17.5V6.5C2 3.6935 3.6901 2 6.4966 2H17.5C20.3062 2 22 3.6935 22 6.5V17.5036ZM11.9998 5.0463C7.9996 5.0926 5 8 5 12C5 16 8 19 12 19C16 19 19 16 19 12C19 8 16 5 11.9998 5.0463ZM11.9998 17.1554C9.1573 17.1554 7 14.8428 7 12C7 9.1575 9.1573 6.8447 11.9998 6.8447C14.8425 6.8447 17 9.1575 17 12C17 14.8428 14.8425 17.1554 11.9998 17.1554ZM16.7803 5.0463C16.7803 3.9771 17.65 3.1072 18.7194 3.1072C19.7887 3.1072 20.6586 3.9769 20.6586 5.0463C20.6586 6.1156 19.7887 6.9855 18.7194 6.9855C17.65 6.9855 16.7803 6.1156 16.7803 5.0463ZM18.1873 5.0463C18.1873 5.3396 18.426 5.5784 18.7194 5.5784C19.0129 5.5784 19.2516 5.3396 19.2516 5.0463C19.2516 4.7527 19.0129 4.5142 18.7194 4.5142C18.426 4.5142 18.1873 4.7527 18.1873 5.0463Z"
              fill="#CFD6D9"/>
        </svg>`,
  },
  {
    name: 'Twitter',
    svg: `<svg width="24" height="20" viewBox="0 0 24 20" fill="none"
             xmlns="http://www.w3.org/2000/svg">
         <path
           d="M24 2.36494C23.1193 2.76871 22.1667 3.03754 21.1736 3.15806C22.191 2.53283 22.9685 1.54298 23.339 0.364629C22.3874 0.941443 21.3325 1.36478 20.2129 1.59139C19.313 0.609775 18.0334 0 16.6182 0C13.8991 0 11.6963 2.25885 11.6963 5.04815C11.6963 5.44162 11.7388 5.82582 11.8228 6.19766C7.73207 5.98548 4.10393 3.9759 1.6764 0.918783C1.25426 1.66349 1.0113 2.53386 1.0113 3.45779C1.0113 5.20678 1.87785 6.75491 3.20094 7.65927C2.39413 7.63558 1.6359 7.40485 0.969799 7.02992V7.09172C0.969799 9.53906 2.66644 11.5816 4.92087 12.0431C4.50785 12.1584 4.07255 12.2202 3.62308 12.2202C3.3042 12.2202 2.99949 12.1873 2.69479 12.1285C3.32344 14.134 5.14156 15.5956 7.29273 15.6399C5.6052 16.9944 3.48642 17.8019 1.17631 17.8019C0.778471 17.8019 0.38873 17.7793 0 17.7309C2.18458 19.1688 4.77105 20 7.54986 20C16.605 20 21.5583 12.3026 21.5583 5.63012C21.5583 5.40969 21.5542 5.19236 21.5441 4.97605C22.5048 4.26945 23.34 3.38054 24 2.36494Z"
           fill="#CFD6D9"/>
        </svg>`,
  },
  {
    name: 'Tiktok',
    svg: `<svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
            width="24" height="24"
            viewBox="0 0 24 24"
            style=" fill:#CFD6D9;"><path d="M 6 3 C 4.3550302 3 3 4.3550302 3 6 L 3 18 C 3 19.64497 4.3550302 21 6 21 L 18 21 C 19.64497 21 21 19.64497 21 18 L 21 6 C 21 4.3550302 19.64497 3 18 3 L 6 3 z M 6 5 L 18 5 C 18.56503 5 19 5.4349698 19 6 L 19 18 C 19 18.56503 18.56503 19 18 19 L 6 19 C 5.4349698 19 5 18.56503 5 18 L 5 6 C 5 5.4349698 5.4349698 5 6 5 z M 12 7 L 12 14 C 12 14.56503 11.56503 15 11 15 C 10.43497 15 10 14.56503 10 14 C 10 13.43497 10.43497 13 11 13 L 11 11 C 9.3550302 11 8 12.35503 8 14 C 8 15.64497 9.3550302 17 11 17 C 12.64497 17 14 15.64497 14 14 L 14 10.232422 C 14.616148 10.671342 15.259118 11 16 11 L 16 9 C 15.952667 9 15.262674 8.7809373 14.78125 8.3613281 C 14.299826 7.941719 14 7.4149911 14 7 L 12 7 z"></path></svg>`,
  },
  {
    name: 'Facebook',
    svg: `<svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
          width="24" height="24"
          viewBox="0 0 24 24"
          style=" fill:#CFD6D9;"><path d="M 12 2 C 6.4889971 2 2 6.4889971 2 12 C 2 17.511003 6.4889971 22 12 22 C 17.511003 22 22 17.511003 22 12 C 22 6.4889971 17.511003 2 12 2 z M 12 4 C 16.430123 4 20 7.5698774 20 12 C 20 16.014467 17.065322 19.313017 13.21875 19.898438 L 13.21875 14.384766 L 15.546875 14.384766 L 15.912109 12.019531 L 13.21875 12.019531 L 13.21875 10.726562 C 13.21875 9.7435625 13.538984 8.8710938 14.458984 8.8710938 L 15.935547 8.8710938 L 15.935547 6.8066406 C 15.675547 6.7716406 15.126844 6.6953125 14.089844 6.6953125 C 11.923844 6.6953125 10.654297 7.8393125 10.654297 10.445312 L 10.654297 12.019531 L 8.4277344 12.019531 L 8.4277344 14.384766 L 10.654297 14.384766 L 10.654297 19.878906 C 6.8702905 19.240845 4 15.970237 4 12 C 4 7.5698774 7.5698774 4 12 4 z"></path></svg>`,
  },
  {
    name: 'Youtube',
    svg: `<svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
          width="24" height="24"
          viewBox="0 0 24 24"
          style=" fill:#CFD6D9;">
          <path d="M 12 4 C 12 4 5.7455469 3.9999687 4.1855469 4.4179688 C 3.3245469 4.6479688 2.6479687 5.3255469 2.4179688 6.1855469 C 1.9999687 7.7455469 2 12 2 12 C 2 12 1.9999687 16.254453 2.4179688 17.814453 C 2.6479687 18.675453 3.3255469 19.352031 4.1855469 19.582031 C 5.7455469 20.000031 12 20 12 20 C 12 20 18.254453 20.000031 19.814453 19.582031 C 20.674453 19.352031 21.352031 18.674453 21.582031 17.814453 C 22.000031 16.254453 22 12 22 12 C 22 12 22.000031 7.7455469 21.582031 6.1855469 C 21.352031 5.3255469 20.674453 4.6479688 19.814453 4.4179688 C 18.254453 3.9999687 12 4 12 4 z M 12 6 C 14.882 6 18.490875 6.1336094 19.296875 6.3496094 C 19.465875 6.3946094 19.604391 6.533125 19.650391 6.703125 C 19.891391 7.601125 20 10.342 20 12 C 20 13.658 19.891391 16.397875 19.650391 17.296875 C 19.605391 17.465875 19.466875 17.604391 19.296875 17.650391 C 18.491875 17.866391 14.882 18 12 18 C 9.119 18 5.510125 17.866391 4.703125 17.650391 C 4.534125 17.605391 4.3956094 17.466875 4.3496094 17.296875 C 4.1086094 16.398875 4 13.658 4 12 C 4 10.342 4.1086094 7.6011719 4.3496094 6.7011719 C 4.3946094 6.5331719 4.533125 6.3946094 4.703125 6.3496094 C 5.508125 6.1336094 9.118 6 12 6 z M 10 8.5351562 L 10 15.464844 L 16 12 L 10 8.5351562 z"></path></svg>`,
  },
];
