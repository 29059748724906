import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SideBarComponent } from './components/side-bar/side-bar.component';
import { VerifyHeaderComponent } from './components/verify-header/verify-header.component';
import { BottomHeaderComponent } from './components/bottom-header/bottom-header.component';
import { RouterModule } from '@angular/router';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { BsDropdownModule, BsDropdownConfig } from 'ngx-bootstrap/dropdown';
import { ChartComponent } from './chart/chart.component';
import { NgChartsModule } from 'ng2-charts';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';
import { SharedModule } from '@frontend/shared';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {VideoModalComponent} from "./video-modal/video-modal.component";
import { LazyLoadImageModule } from "ng-lazyload-image";
import {ProgressbarModule} from "ngx-bootstrap/progressbar";

@NgModule({
  declarations: [
    SideBarComponent,
    VerifyHeaderComponent,
    BottomHeaderComponent,
    ChartComponent,
    VideoModalComponent
  ],
    imports: [
        CommonModule,
        RouterModule,
        TooltipModule,
        BsDropdownModule,
        NgChartsModule,
        NgxIntlTelInputModule,
        SharedModule,
        ReactiveFormsModule,
        FormsModule,
        LazyLoadImageModule,
        ProgressbarModule,
    ],
  exports: [
    SideBarComponent,
    VerifyHeaderComponent,
    BottomHeaderComponent,
    ChartComponent,
  ],
  providers: [BsDropdownConfig],
})
export class CommonComponentModule {}
