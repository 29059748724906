<div class="body_bg ht100">
  <!-- <frontend-verify-header></frontend-verify-header> -->
  <div class="help_main">
    <div class="breadcrumb" *ngIf="!isModelShow" (click)="redirectBack()">
      <svg
        width="10"
        height="20"
        viewBox="0 0 10 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          _ngcontent-epi-c101=""
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M9.81761 0.636891C9.56018 0.33776 9.12633 0.320004 8.84856 0.597231L1.0797 8.35108C0.173953 9.25508 0.173953 10.7457 1.0797 11.6497L8.84856 19.4035C9.12633 19.6808 9.56018 19.663 9.81761 19.3639C10.075 19.0648 10.0585 18.5975 9.78078 18.3203L2.01192 10.5665C1.69178 10.2469 1.69178 9.75385 2.01192 9.43432L9.78078 1.68048C10.0585 1.40325 10.075 0.936021 9.81761 0.636891Z"
          fill="#CFD6D9"
        ></path>
      </svg>
      <span>Back</span>
    </div>

    <div *ngIf="isModelShow"  >
      <div class="close-icon">
        <img
          class="close-icon cursor-pointer"
          src="/assets/images/Cross1.png"
          alt=""
          (click)="bsModalRef.hide()"
        />
      </div>
    </div>

    <div class="mobile_headers_min">
      <div class="mobile_headers">
        <div class="back" *ngIf="!isModelShow" (click)="redirectBack()">
          <svg
            _ngcontent-fbs-c106=""
            width="10"
            height="20"
            viewBox="0 0 10 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              _ngcontent-fbs-c106=""
              _ngcontent-epi-c101=""
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M9.81761 0.636891C9.56018 0.33776 9.12633 0.320004 8.84856 0.597231L1.0797 8.35108C0.173953 9.25508 0.173953 10.7457 1.0797 11.6497L8.84856 19.4035C9.12633 19.6808 9.56018 19.663 9.81761 19.3639C10.075 19.0648 10.0585 18.5975 9.78078 18.3203L2.01192 10.5665C1.69178 10.2469 1.69178 9.75385 2.01192 9.43432L9.78078 1.68048C10.0585 1.40325 10.075 0.936021 9.81761 0.636891Z"
              fill="#CFD6D9"
            ></path>
          </svg>
        </div>

<!--        <div class="back" *ngIf="isModelShow">-->
<!--          <div class="close-icon">-->
<!--            <img-->
<!--              class="close-icon cursor-pointer"-->
<!--              src="/assets/images/Cross1.png"-->
<!--              alt=""-->
<!--              (click)="bsModalRef.hide()"-->
<!--            />-->
<!--          </div>-->
<!--        </div>-->
      </div>
    </div>

    <form [formGroup]="contactForm">
      <div class="help_container_main">
        <div class="row">
          <div class="col-lg-6">
            <div class="help_fields">
              <h1>How may we help you?</h1>
              <div class="field">
                <label>Contact reason</label>
                <select formControlName="reason">
                  <option value="">Choose a contact reason</option>
                  <option
                    *ngFor="let reason of reasonList"
                    [value]="reason?.value" 
                  >
                    {{ reason?.label }}
                  </option>
                </select>
              </div>
              <div class="text_area_filed">
                <label>Message</label>
                <textarea
                  placeholder="Write your message here"
                  formControlName="message"
                ></textarea>
              </div>
              <div class="submit_btn">
                <button (click)="saveContactDetails()">Submit</button>
              </div>
            </div>
          </div>

          <div class="col-lg-6">
            <div class="kwot_logo" [ngClass]="{'model-img': isModelShow}">
              <img src="assets/images/help_img.png"/>
            </div>
          </div>
        </div>
        <div class="sucess_bar_main">
          <div class="top_sucess_bar">
            <div class="msg_done">
              <img src="assets/images/checkimg.png" />
              <span>Your message has successfully sent.</span>
            </div>
            <div class="cross_icon">
              <a href="#"><img src="assets/images/crs.png" /></a>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>
 
<frontend-bottom-header></frontend-bottom-header>