import { createReducer, on, Action } from '@ngrx/store';
import {
  ResetArtistState,
  GetArtistProfile,
  GetArtistProfileError,
  GetArtistProfileSuccess,
  StudioSetupError,
  StudioSetupSuccess,
  StudioSetup,
  CheckNameAvailability,
  CheckNameAvailabilitySuccess,
  CheckNameAvailabilityError,
  GetPodcastList,
  GetPodcastListSuccess,
  GetPodcastListError,
  GetPodcastEpisodeList,
  GetPodcastEpisodeListSuccess,
  GetPodcastEpisodeListError,
  DeletePodcast,
  DeletePodcastSuccess,
  DeletePodcastError,
  DeleteEpisode,
  DeleteEpisodeSuccess,
  DeleteEpisodeError,
  GetAllCategory,
  GetAllCategorySuccess,
  GetAllCategoryError,
  AddUpdatePodcast,
  AddUpdatePodcastSuccess,
  AddUpdatePodcastError,
  GetPodcastById,
  GetPodcastByIdSuccess,
  GetPodcastByIdError,
  AddUpdateEpisode,
  AddUpdateEpisodeError,
  AddUpdateEpisodeSuccess,
  GetEpisodeById,
  GetEpisodeByIdSuccess,
  GetEpisodeByIdError,
  AddUpdateAllEpisode,
  AddUpdateAllEpisodeSuccess,
  AddUpdateAllEpisodeError,
  UpdatePodcastTerms,
  UpdatePodcastTermsSuccess,
  UpdatePodcastTermsError,
  GetSkitsList,
  GetSkitsListSuccess,
  GetSkitsListError,
  DeleteSkit,
  DeleteSkitSuccess,
  DeleteSkitError,
  AddUpdateSkit,
  AddUpdateSkitSuccess,
  AddUpdateSkitError,
  GetSkitById,
  GetSkitByIdSuccess,
  GetSkitByIdError,
  UpdateSkitsTerms,
  UpdateSkitsTermsSuccess,
  UpdateSkitsTermsError,
  UpdateArtist,
  UpdateArtistSuccess,
  UpdateArtistError,
  VerifyEmailPhone,
  VerifyEmailPhoneSuccess,
  VerifyEmailPhoneError,
  UpdatePassword,
  UpdatePasswordSuccess,
  UpdatePasswordError,
  DeleteProfile,
  DeleteProfileSuccess,
  DeleteProfileError,
  GetMusicList,
  GetMusicListSuccess,
  GetMusicListError,
  GetMusicTrackList,
  GetMusicTrackListSuccess,
  GetMusicTrackListError,
  DeleteMusic,
  DeleteMusicSuccess,
  DeleteMusicError,
  DeleteTrack,
  DeleteTrackSuccess,
  DeleteTrackError,
  GetAllGenres,
  GetAllGenresSuccess,
  GetAllGenresError,
  AddUpdateMusic,
  AddUpdateMusicSuccess,
  AddUpdateMusicError,
  GetMusicById,
  GetMusicByIdSuccess,
  GetMusicByIdError,
  AddUpdateTrack,
  AddUpdateTrackSuccess,
  AddUpdateTrackError,
  GetTrackById,
  GetTrackByIdSuccess,
  GetTrackByIdError,
  AddUpdateAllTracks,
  AddUpdateAllTracksSuccess,
  AddUpdateAllTracksError,
  UpdateMusicTerms,
  UpdateMusicTermsSuccess,
  UpdateMusicTermsError,
  GetAllBankDetail,
  GetAllBankDetailSuccess,
  GetAllBankDetailError,
  AddUpdateBankDetail,
  AddUpdateBankDetailSuccess,
  AddUpdateBankDetailError,
  DeleteBankDetail,
  DeleteBankDetailSuccess,
  DeleteBankDetailError,
  AddFanClub,
  AddFanClubSuccess,
  AddFanClubNameSuccess,
  AddFanClubError,
  GetEventList,
  GetEventListSuccess,
  GetEventListError,
  AddFanclubName,
  AddExclusiveContent,
  AddExclusiveContentSuccess,
  AddExclusiveContentError,
  AddUpdateDiscounts,
  AddUpdateDiscountsSuccess,
  AddUpdateDiscountsError,
  AddUpdateLiveShow,
  AddUpdateLiveShowSuccess,
  AddUpdateLiveShowError,
  GetSubscriptionPlansList,
  GetSubscriptionPlansListSuccess,
  GetSubscriptionPlansListError,
  UpdatePlan,
  UpdatePlanSuccess,
  UpdatePlanError,
  GetExclusiveContentList,
  GetExclusiveContentListSuccess,
  GetExclusiveContentListError,
  GetLiveShowsList,
  GetLiveShowsListSuccess,
  GetLiveShowsListError,
  GetDiscountList,
  GetDiscountListSuccess,
  GetDiscountListError,
  GetSubscriptionPlansListArtis,
  GetSubscriptionPlansListArtisSuccess,
  GetSubscriptionPlansListArtisError,
  DeleteContent,
  DeleteContentSuccess,
  DeleteContentError,
  AddUpdateMerchandising,
  AddUpdateMerchandisingSuccess,
  AddUpdateMerchandisingError,
  GetMerchandisingUrl,
  GetMerchandisingUrlSuccess,
  GetMerchandisingUrlError,
  GetBillingDetails,
  GetBillingDetailsSuccess,
  GetBillingDetailsError,
  GenerateRtmToken,
  GenerateRtmTokenSuccess,
  GenerateRtmTokenError,
  GetArtistDashboard,
  GetArtistDashboardSuccess,
  GetArtistDashboardError,
  DeleteMerchandiseSuccess,
  DeleteMerchandise,
  DeleteMerchandiseError,
  AddUpdateRadioStation,
  AddUpdateRadioStationError,
  AddUpdateRadioStationSuccess,
  DeleteRadioStation, DeleteRadioStationError,
  DeleteRadioStationSuccess,
  GetAllRadioStations,
  GetAllRadioStationsError,
  GetAllRadioStationsSuccess,
  GetRadioStationById,
  GetRadioStationByIdError,
  GetRadioStationByIdSuccess
} from './artist.actions';
import {
  BankDetail,
  Category,
  Discount,
  Episode,
  EventResponse,
  ExclusiveContent,
  FanClub,
  LiveShow,
  Music,
  PaginatedMusic,
  PaginatedPodcast,
  PaginatedSkits,
  SubscriptionPlan,
  Podcast,
  Skits,
  Tracks,
  User,
  ExclusiveContentList,
  ShowsList,
  DiscountList,
  MERCAHANDISING,
  BillingDetails
} from '@frontend/data-models';

export interface ArtistState {
  success: string;
  error: string;
  artist: User;
  dashboard: any;
  isNameAvailable: string;
  podcasts: PaginatedPodcast;
  musics: PaginatedMusic;
  episodes: Episode[];
  tracks: Tracks[];
  categories: Category[];
  genres: Category[];
  podcast: Podcast;
  music: Music;
  episode: Episode;
  track: Tracks;
  skits: PaginatedSkits;
  skit: Skits;
  bankDetails: BankDetail[];
  bankDetail: BankDetail;
  fanClub: FanClub;
  events: EventResponse;
  exclusiveContent: ExclusiveContent;
  discounts: Discount;
  liveShows: LiveShow;
  plans: SubscriptionPlan;
  contentlist: ExclusiveContentList;
  showslist: ShowsList;
  discountlist: DiscountList;
  artistplans: SubscriptionPlan;
  urlslist:MERCAHANDISING,
  billingDetails:BillingDetails
  rtmToken:any;
  radio_stations: any;
  radio_station: any;
}

const initialArtistState: ArtistState = {
  error: '',
  success: '',
  artist: null,
  dashboard: null,
  isNameAvailable: '',
  podcasts: null,
  episodes: [],
  categories: [],
  podcast: null,
  episode: null,
  skits: null,
  skit: null,
  musics: null,
  tracks: [],
  genres: [],
  music: null,
  track: null,
  bankDetails: null,
  bankDetail: null,
  fanClub: null,
  events: null,
  exclusiveContent: null,
  discounts: null,
  liveShows: null,
  plans: null,
  contentlist: null,
  showslist: null,
  discountlist: null,
  artistplans: null,
  urlslist:null,
  billingDetails:null,
  rtmToken:null,
  radio_stations: null,
  radio_station: null
};

const artistReducer = createReducer(
  initialArtistState,
  on(ResetArtistState, (state, { params }) => {
    return {
      ...state,
      ...params,
    };
  }),

  on(GetArtistDashboard, (state) => ({
    ...state,
    dashboard: null,
    error: '',
    success: ''
  })),
  on(GetArtistDashboardSuccess, (state, {dashboard}) => ({
    ...state,
    dashboard,
    error: '',
    success: ''
  })),
  on(GetArtistDashboardError, (state, {error}) => ({
    ...state,
    dashboard: null,
    error,
    success: ''
  })),

  on(GetBillingDetails, (state) => ({
    ...state,
    error: '',
    success: '',
    plans: null,
  })),
  on(GetBillingDetailsSuccess, (state, { billingDetails }) => ({
    ...state,
    error: '',
    success: '',
    billingDetails,
  })),
  on(GetBillingDetailsError, (state, { error }) => ({
    ...state,
    error,
    success: '',
    plans: null,
  })),

  on(GetMerchandisingUrl, (state) => ({
    ...state,
    error: '',
    success: '',
    plans: null,
  })),
  on(GetMerchandisingUrlSuccess, (state, { urlslist }) => ({
    ...state,
    error: '',
    success: '',
    urlslist,
  })),
  on(GetMerchandisingUrlError, (state, { error }) => ({
    ...state,
    error,
    success: '',
    plans: null,
  })),

  on(AddUpdateMerchandising, (state) => ({ ...state, error: '', success: '' })),
  on(AddUpdateMerchandisingSuccess, (state) => ({
    ...state,
    error: '',
    success: 'Merchandising updated successfully',
  })),
  on(AddUpdateMerchandisingError, (state, { error }) => ({
    ...state,
    error,
    success: '',
  })),

  on(DeleteContent, (state) => ({ ...state, error: '', success: '' })),
  on(DeleteContentSuccess, (state) => ({
    ...state,
    error: '',
    success: 'Content deleted successfully',
  })),
  on(DeleteContentError, (state, { error }) => ({
    ...state,
    error,
    success: '',
  })),

  on(GetDiscountList, (state) => ({
    ...state,
    error: '',
    success: '',
    plans: null,
  })),
  on(GetDiscountListSuccess, (state, { discountlist }) => ({
    ...state,
    error: '',
    success: '',
    discountlist,
  })),
  on(GetDiscountListError, (state, { error }) => ({
    ...state,
    error,
    success: '',
    plans: null,
  })),

  on(GetLiveShowsList, (state) => ({
    ...state,
    error: '',
    success: '',
    plans: null,
  })),
  on(GetLiveShowsListSuccess, (state, { showslist }) => ({
    ...state,
    error: '',
    success: '',
    showslist,
  })),
  on(GetLiveShowsListError, (state, { error }) => ({
    ...state,
    error,
    success: '',
    plans: null,
  })),

  on(UpdatePlan, (state) => ({ ...state, error: '', success: '' })),
  on(UpdatePlanSuccess, (state) => ({
    ...state,
    error: '',
    success: 'Plan updated successfully',
  })),
  on(UpdatePlanError, (state, { error }) => ({ ...state, error, success: '' })),

  on(GetExclusiveContentList, (state) => ({
    ...state,
    error: '',
    success: '',
    plans: null,
  })),
  on(GetExclusiveContentListSuccess, (state, { contentlist }) => ({
    ...state,
    error: '',
    success: '',
    contentlist,
  })),
  on(GetExclusiveContentListError, (state, { error }) => ({
    ...state,
    error,
    success: '',
    plans: null,
  })),

  on(GetSubscriptionPlansListArtis, (state) => ({
    ...state,
    error: '',
    success: '',
    v: null,
  })),
  on(GetSubscriptionPlansListArtisSuccess, (state, { artistplans }) => ({
    ...state,
    error: '',
    success: '',
    artistplans,
  })),
  on(GetSubscriptionPlansListArtisError, (state, { error }) => ({
    ...state,
    error,
    success: '',
    artistplans: null,
  })),

  on(GetSubscriptionPlansList, (state) => ({
    ...state,
    error: '',
    success: '',
    plans: null,
  })),
  on(GetSubscriptionPlansListSuccess, (state, { plans }) => ({
    ...state,
    error: '',
    success: '',
    plans,
  })),
  on(GetSubscriptionPlansListError, (state, { error }) => ({
    ...state,
    error,
    success: '',
    plans: null,
  })),

  on(AddUpdateLiveShow, (state) => ({ ...state, error: '', success: '' })),
  on(AddUpdateLiveShowSuccess, (state, { liveShows }) => ({
    ...state,
    error: '',
    success: 'LiveShows updated successfully',
    liveShows,
  })),
  on(AddUpdateLiveShowError, (state, { error }) => ({
    ...state,
    error,
    success: '',
  })),

  on(GetArtistProfile, (state) => ({
    ...state,
    error: '',
    success: '',
    artist: null,
  })),
  on(GetArtistProfileSuccess, (state, { artist }) => ({
    ...state,
    error: '',
    success: '',
    artist,
  })),
  on(GetArtistProfileError, (state, { error }) => ({
    ...state,
    error,
    success: '',
    artist: null,
  })),

  on(AddFanClub, (state) => ({ ...state, error: '', success: '' })),
  on(AddFanClubSuccess, (state, { fanClub }) => ({
    ...state,
    error: '',
    success: 'Event updated successfully',
    fanClub,
  })),
  on(AddFanClubNameSuccess, (state, { fanClub }) => ({
    ...state,
    error: '',
    success: 'Fanclub name updated successfully',
    fanClub,
  })),
  on(AddFanClubError, (state, { error }) => ({ ...state, error, success: '' })),

  on(AddUpdateDiscounts, (state) => ({ ...state, error: '', success: '' })),
  on(AddUpdateDiscountsSuccess, (state, { discounts }) => ({
    ...state,
    error: '',
    success: 'Discounts updated successfully',
    discounts,
  })),
  on(AddUpdateDiscountsError, (state, { error }) => ({
    ...state,
    error,
    success: '',
  })),

  on(AddExclusiveContent, (state) => ({ ...state, error: '', success: '' })),
  on(AddFanclubName, (state) => ({ ...state, error: '', success: '' })),
  on(AddExclusiveContentSuccess, (state, { exclusiveContent }) => ({
    ...state,
    error: '',
    success: 'Exclusive Content updated successfully',
    exclusiveContent,
  })),
  on(AddExclusiveContentError, (state, { error }) => ({
    ...state,
    error,
    success: '',
  })),

  on(StudioSetup, (state) => ({ ...state, error: '', success: '' })),
  on(StudioSetupSuccess, (state, { artist }) => ({
    ...state,
    error: '',
    success: '',
    artist,
  })),
  on(StudioSetupError, (state, { error }) => ({
    ...state,
    error,
    success: '',
  })),

  on(CheckNameAvailability, (state) => ({
    ...state,
    error: '',
    success: '',
    isNameAvailable: '',
  })),
  on(CheckNameAvailabilitySuccess, (state, { isNameAvailable }) => ({
    ...state,
    error: '',
    success: '',
    isNameAvailable,
  })),
  on(CheckNameAvailabilityError, (state, { error, isNameAvailable }) => ({
    ...state,
    error,
    success: '',
    isNameAvailable,
  })),

  on(GetPodcastList, (state) => ({
    ...state,
    error: '',
    podcasts: null,
    success: '',
  })),
  on(GetPodcastListSuccess, (state, { podcasts }) => ({
    ...state,
    error: '',
    success: '',
    podcasts,
  })),
  on(GetPodcastListError, (state, { error }) => ({
    ...state,
    error,
    podcasts: null,
    success: '',
  })),

  on(GetEventList, (state) => ({
    ...state,
    error: '',
    events: null,
    success: '',
  })),
  on(GetEventListSuccess, (state, { events }) => ({
    ...state,
    error: '',
    success: '',
    events,
  })),
  on(GetEventListError, (state, { error }) => ({
    ...state,
    error,
    events: null,
    success: '',
  })),

  on(GetPodcastEpisodeList, (state) => ({
    ...state,
    error: '',
    episodes: [],
    success: '',
  })),
  on(GetPodcastEpisodeListSuccess, (state, { episodes }) => ({
    ...state,
    error: '',
    success: '',
    episodes,
  })),
  on(GetPodcastEpisodeListError, (state, { error }) => ({
    ...state,
    error,
    episodes: [],
    success: '',
  })),

  on(DeletePodcast, (state) => ({ ...state, error: '', success: '' })),
  on(DeletePodcastSuccess, (state) => ({
    ...state,
    error: '',
    success: 'Podcast deleted successfully',
  })),
  on(DeletePodcastError, (state, { error }) => ({
    ...state,
    error,
    success: '',
  })),

  on(DeleteEpisode, (state) => ({ ...state, error: '', success: '' })),
  on(DeleteEpisodeSuccess, (state) => ({
    ...state,
    error: '',
    success: 'Episode deleted successfully',
  })),
  on(DeleteEpisodeError, (state, { error }) => ({
    ...state,
    error,
    success: '',
  })),

  on(GetAllCategory, (state) => ({
    ...state,
    error: '',
    success: '',
    categories: [],
  })),
  on(GetAllCategorySuccess, (state, { categories }) => ({
    ...state,
    error: '',
    success: '',
    categories,
  })),
  on(GetAllCategoryError, (state, { error }) => ({
    ...state,
    error,
    success: '',
    categories: [],
  })),

  on(AddUpdatePodcast, (state) => ({ ...state, error: '', success: '' })),
  on(AddUpdatePodcastSuccess, (state, { podcast }) => ({
    ...state,
    error: '',
    success: '',
    podcast,
  })),
  on(AddUpdatePodcastError, (state, { error }) => ({
    ...state,
    error,
    success: '',
  })),

  on(GetPodcastById, (state) => ({
    ...state,
    error: '',
    success: '',
    podcast: null,
  })),
  on(GetPodcastByIdSuccess, (state, { podcast }) => ({
    ...state,
    error: '',
    success: '',
    podcast,
  })),
  on(GetPodcastByIdError, (state, { error }) => ({
    ...state,
    error,
    success: '',
    podcast: null,
  })),

  on(GetEpisodeById, (state) => ({
    ...state,
    error: '',
    success: '',
    podcast: null,
  })),
  on(GetEpisodeByIdSuccess, (state, { episode }) => ({
    ...state,
    error: '',
    success: '',
    episode,
  })),
  on(GetEpisodeByIdError, (state, { error }) => ({
    ...state,
    error,
    success: '',
    podcast: null,
  })),

  on(AddUpdateEpisode, (state) => ({ ...state, error: '', success: '' })),
  on(AddUpdateEpisodeSuccess, (state, { podcast }) => ({
    ...state,
    error: '',
    success: 'Episode updated successfully',
    podcast,
  })),
  on(AddUpdateEpisodeError, (state, { error }) => ({
    ...state,
    error,
    success: '',
  })),

  on(AddUpdateAllEpisode, (state) => ({ ...state, error: '', success: '' })),
  on(AddUpdateAllEpisodeSuccess, (state, { podcast }) => ({
    ...state,
    error: '',
    success: 'Episode updated successfully',
    podcast,
  })),
  on(AddUpdateAllEpisodeError, (state, { error }) => ({
    ...state,
    error,
    success: '',
  })),

  on(UpdatePodcastTerms, (state) => ({ ...state, error: '', success: '' })),
  on(UpdatePodcastTermsSuccess, (state) => ({
    ...state,
    error: '',
    success: 'Podcast updated successfully',
  })),
  on(UpdatePodcastTermsError, (state, { error }) => ({
    ...state,
    error,
    success: '',
  })),

  on(GetSkitsList, (state) => ({
    ...state,
    error: '',
    skits: null,
    success: '',
  })),
  on(GetSkitsListSuccess, (state, { skits }) => ({
    ...state,
    error: '',
    success: '',
    skits,
  })),
  on(GetSkitsListError, (state, { error }) => ({
    ...state,
    error,
    skits: null,
    success: '',
  })),

  on(DeleteSkit, (state) => ({ ...state, error: '', success: '' })),
  on(DeleteSkitSuccess, (state) => ({
    ...state,
    error: '',
    success: 'Video deleted successfully',
  })),
  on(DeleteSkitError, (state, { error }) => ({ ...state, error, success: '' })),

  on(AddUpdateSkit, (state) => ({ ...state, error: '', success: '' })),
  on(AddUpdateSkitSuccess, (state, { skit }) => ({
    ...state,
    error: '',
    success: '',
    skit,
  })),
  on(AddUpdateSkitError, (state, { error }) => ({
    ...state,
    error,
    success: '',
  })),

  on(GetSkitById, (state) => ({
    ...state,
    error: '',
    success: '',
    skit: null,
  })),
  on(GetSkitByIdSuccess, (state, { skit }) => ({
    ...state,
    error: '',
    success: '',
    skit,
  })),
  on(GetSkitByIdError, (state, { error }) => ({
    ...state,
    error,
    success: '',
    skit: null,
  })),

  on(UpdateSkitsTerms, (state) => ({ ...state, error: '', success: '' })),
  on(UpdateSkitsTermsSuccess, (state) => ({
    ...state,
    error: '',
    success: 'Video updated successfully',
  })),
  on(UpdateSkitsTermsError, (state, { error }) => ({
    ...state,
    error,
    success: '',
  })),

  on(UpdateArtist, (state) => ({ ...state, error: '', success: '' })),
  on(UpdateArtistSuccess, (state, { artist }) => ({
    ...state,
    error: '',
    success: '',
    artist,
  })),
  on(UpdateArtistError, (state, { error }) => ({
    ...state,
    error,
    success: '',
  })),

  on(VerifyEmailPhone, (state) => ({ ...state, error: '', success: '' })),
  on(VerifyEmailPhoneSuccess, (state) => ({
    ...state,
    error: '',
    success: '---IGNORE---',
  })),
  on(VerifyEmailPhoneError, (state, { error }) => ({
    ...state,
    error,
    success: '',
  })),

  on(UpdatePassword, (state) => ({ ...state, error: '', success: '' })),
  on(UpdatePasswordSuccess, (state) => ({
    ...state,
    error: '',
    success: '---IGNORE---',
  })),
  on(UpdatePasswordError, (state, { error }) => ({
    ...state,
    error,
    success: '',
  })),

  on(DeleteProfile, (state) => ({ ...state, error: '', success: '' })),
  on(DeleteProfileSuccess, (state) => ({
    ...state,
    error: '',
    success: '---IGNORE---',
  })),
  on(DeleteProfileError, (state, { error }) => ({
    ...state,
    error,
    success: '',
  })),

  on(GetMusicList, (state) => ({
    ...state,
    error: '',
    musics: null,
    success: '',
  })),
  on(GetMusicListSuccess, (state, { musics }) => ({
    ...state,
    error: '',
    success: '',
    musics,
  })),
  on(GetMusicListError, (state, { error }) => ({
    ...state,
    error,
    musics: null,
    success: '',
  })),

  on(GetMusicTrackList, (state) => ({
    ...state,
    error: '',
    tracks: [],
    success: '',
  })),
  on(GetMusicTrackListSuccess, (state, { tracks }) => ({
    ...state,
    error: '',
    success: '',
    tracks,
  })),
  on(GetMusicTrackListError, (state, { error }) => ({
    ...state,
    error,
    tracks: [],
    success: '',
  })),

  on(DeleteMusic, (state) => ({ ...state, error: '', success: '' })),
  on(DeleteMusicSuccess, (state) => ({
    ...state,
    error: '',
    success: 'Album deleted successfully',
  })),
  on(DeleteMusicError, (state, { error }) => ({
    ...state,
    error,
    success: '',
  })),

  on(DeleteTrack, (state) => ({ ...state, error: '', success: '' })),
  on(DeleteTrackSuccess, (state) => ({
    ...state,
    error: '',
    success: 'Track deleted successfully',
  })),
  on(DeleteTrackError, (state, { error }) => ({
    ...state,
    error,
    success: '',
  })),

  on(GetAllGenres, (state) => ({
    ...state,
    error: '',
    success: '',
    genres: [],
  })),
  on(GetAllGenresSuccess, (state, { genres }) => ({
    ...state,
    error: '',
    success: '',
    genres,
  })),
  on(GetAllGenresError, (state, { error }) => ({
    ...state,
    error,
    success: '',
    genres: [],
  })),

  on(AddUpdateMusic, (state) => ({ ...state, error: '', success: '' })),
  on(AddUpdateMusicSuccess, (state, { music }) => ({
    ...state,
    error: '',
    success: '',
    music,
  })),
  on(AddUpdateMusicError, (state, { error }) => ({
    ...state,
    error,
    success: '',
  })),

  on(GetMusicById, (state) => ({
    ...state,
    error: '',
    success: '',
    music: null,
  })),
  on(GetMusicByIdSuccess, (state, { music }) => ({
    ...state,
    error: '',
    success: '',
    music,
  })),
  on(GetMusicByIdError, (state, { error }) => ({
    ...state,
    error,
    success: '',
    music: null,
  })),

  on(GetTrackById, (state) => ({
    ...state,
    error: '',
    success: '',
    music: null,
  })),
  on(GetTrackByIdSuccess, (state, { track }) => ({
    ...state,
    error: '',
    success: '',
    track,
  })),
  on(GetTrackByIdError, (state, { error }) => ({
    ...state,
    error,
    success: '',
    music: null,
  })),

  on(AddUpdateTrack, (state) => ({ ...state, error: '', success: '' })),
  on(AddUpdateTrackSuccess, (state, { music }) => ({
    ...state,
    error: '',
    success: 'Tracks updated successfully',
    music,
  })),
  on(AddUpdateTrackError, (state, { error }) => ({
    ...state,
    error,
    success: '',
  })),

  on(AddUpdateAllTracks, (state) => ({ ...state, error: '', success: '' })),
  on(AddUpdateAllTracksSuccess, (state, { music }) => ({
    ...state,
    error: '',
    success: 'Tracks updated successfully',
    music,
  })),
  on(AddUpdateAllTracksError, (state, { error }) => ({
    ...state,
    error,
    success: '',
  })),

  on(UpdateMusicTerms, (state) => ({ ...state, error: '', success: '' })),
  on(UpdateMusicTermsSuccess, (state) => ({
    ...state,
    error: '',
    success: 'Album updated successfully',
  })),
  on(UpdateMusicTermsError, (state, { error }) => ({
    ...state,
    error,
    success: '',
  })),

  on(GetAllBankDetail, (state) => ({
    ...state,
    error: '',
    success: '',
    bankDetails: [],
  })),
  on(GetAllBankDetailSuccess, (state, { bankDetails }) => ({
    ...state,
    error: '',
    success: '',
    bankDetails,
  })),
  on(GetAllBankDetailError, (state, { error }) => ({
    ...state,
    error,
    success: '',
    bankDetails: [],
  })),

  on(AddUpdateBankDetail, (state) => ({ ...state, error: '', success: '' })),
  on(AddUpdateBankDetailSuccess, (state, { bankDetail }) => ({
    ...state,
    error: '',
    success: 'Data ',
    bankDetail,
  })),
  on(AddUpdateBankDetailError, (state, { error }) => ({
    ...state,
    error,
    success: '',
  })),

  on(DeleteBankDetail, (state) => ({ ...state, error: '', success: '' })),
  on(DeleteBankDetailSuccess, (state) => ({
    ...state,
    error: '',
    success: 'Bank Detail deleted successfully',
  })),
  on(DeleteBankDetailError, (state, { error }) => ({
    ...state,
    error,
    success: '',
  })),

on(GenerateRtmToken, (state) => ({
  ...state,
  error: '',
  success: '',
  rtmToken: [],
})),
  on(GenerateRtmTokenSuccess, (state, { rtmToken }) => ({
    ...state,
    error: '',
    success: '',
    rtmToken,
  })),
  on(GenerateRtmTokenError, (state, { error }) => ({
    ...state,
    error,
    success: '',
    rtmToken: [],
  })),

  on(DeleteMerchandise, (state) => ({ ...state, error: '', success: '' })),
  on(DeleteMerchandiseSuccess, (state) => ({
    ...state,
    error: '',
    success: '---IGNORE---',
  })),
  on(DeleteMerchandiseError, (state, { error }) => ({
    ...state,
    error,
    success: '',
  })),

  on(GetAllRadioStations, (state) => ({...state, error: '', success: '', radio_stations: null})),
  on(GetAllRadioStationsSuccess, (state, {radio_stations}) => ({...state, error: '', success: '', radio_stations})),
  on(GetAllRadioStationsError, (state, {error}) => ({...state, error, success: '', radio_stations: null})),

  on(GetRadioStationById, (state) => ({...state, error: '', success: '', radio_station: null})),
  on(GetRadioStationByIdSuccess, (state, {radio_station}) => ({...state, error: '', success: '', radio_station})),
  on(GetRadioStationByIdError, (state, {error}) => ({...state, error, success: '', radio_station: null})),

  on(AddUpdateRadioStation, (state) => ({...state, error: '', success: ''})),
  on(AddUpdateRadioStationSuccess, (state) => ({...state, error: '', success: 'Updated successfully'})),
  on(AddUpdateRadioStationError, (state, {error}) => ({...state, error, success: ''})),

  on(DeleteRadioStation, (state) => ({...state, error: '', success: ''})),
  on(DeleteRadioStationSuccess, (state, {success}) => ({...state, error: '', success})),
  on(DeleteRadioStationError, (state, {error}) => ({...state, error, success: ''})),

);

export function reducer(state = initialArtistState, action: Action) {
  return artistReducer(state, action);
}
