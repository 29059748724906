import { Injectable } from '@angular/core';
import { createEffect, Actions, ofType } from '@ngrx/effects';
import { catchError, map, of, switchMap } from 'rxjs';
import { LoaderService, LocalstorageService } from '@frontend/app-config';
import { ArtistService } from '../services/artist.service';
import {
  GetArtistProfileSuccess,
  GetArtistProfileError,
  GetArtistProfile,
  StudioSetup,
  StudioSetupSuccess,
  StudioSetupError,
  CheckNameAvailability,
  CheckNameAvailabilitySuccess,
  CheckNameAvailabilityError,
  GetPodcastList,
  GetPodcastListSuccess,
  GetPodcastListError,
  GetPodcastEpisodeList,
  GetPodcastEpisodeListSuccess,
  GetPodcastEpisodeListError,
  DeletePodcast,
  DeletePodcastSuccess,
  DeletePodcastError,
  DeleteEpisode,
  DeleteEpisodeSuccess,
  DeleteEpisodeError,
  GetAllCategory,
  GetAllCategorySuccess,
  GetAllCategoryError,
  GetPodcastById,
  GetPodcastByIdSuccess,
  GetPodcastByIdError,
  AddUpdatePodcast,
  AddUpdatePodcastSuccess,
  AddUpdatePodcastError,
  GetEpisodeById,
  GetEpisodeByIdSuccess,
  GetEpisodeByIdError,
  AddUpdateEpisode,
  AddUpdateEpisodeSuccess,
  AddUpdateEpisodeError,
  AddUpdateAllEpisode,
  AddUpdateAllEpisodeSuccess,
  AddUpdateAllEpisodeError,
  UpdatePodcastTerms,
  UpdatePodcastTermsSuccess,
  UpdatePodcastTermsError,
  GetSkitsList,
  GetSkitsListSuccess,
  GetSkitsListError,
  DeleteSkit,
  DeleteSkitSuccess,
  DeleteSkitError,
  AddUpdateSkit,
  AddUpdateSkitSuccess,
  AddUpdateSkitError,
  GetSkitById,
  GetSkitByIdSuccess,
  GetSkitByIdError,
  UpdateSkitsTerms,
  UpdateSkitsTermsSuccess,
  UpdateSkitsTermsError,
  UpdateArtist,
  UpdateArtistSuccess,
  UpdateArtistError,
  VerifyEmailPhone,
  VerifyEmailPhoneSuccess,
  VerifyEmailPhoneError,
  UpdatePassword,
  UpdatePasswordSuccess,
  UpdatePasswordError,
  DeleteProfile,
  DeleteProfileSuccess,
  DeleteProfileError,
  GetMusicList,
  GetMusicListSuccess,
  GetMusicListError,
  GetAllGenres,
  GetAllGenresSuccess,
  GetAllGenresError,
  AddUpdateMusic,
  AddUpdateMusicSuccess,
  AddUpdateMusicError,
  GetMusicTrackList,
  GetMusicTrackListSuccess,
  GetMusicTrackListError,
  DeleteMusic,
  DeleteMusicSuccess,
  DeleteMusicError,
  DeleteTrack,
  DeleteTrackSuccess,
  DeleteTrackError,
  GetMusicById,
  GetMusicByIdSuccess,
  GetMusicByIdError,
  GetTrackById,
  GetTrackByIdSuccess,
  GetTrackByIdError,
  AddUpdateTrack,
  AddUpdateTrackSuccess,
  AddUpdateTrackError,
  AddUpdateAllTracks,
  AddUpdateAllTracksSuccess,
  AddUpdateAllTracksError,
  UpdateMusicTerms,
  UpdateMusicTermsSuccess,
  UpdateMusicTermsError,
  GetAllBankDetail,
  GetAllBankDetailSuccess,
  GetAllBankDetailError,
  AddUpdateBankDetailError,
  AddUpdateBankDetail,
  AddUpdateBankDetailSuccess,
  DeleteBankDetail,
  DeleteBankDetailSuccess,
  DeleteBankDetailError,
  AddFanClub,
  AddFanClubSuccess,
  AddFanClubError,
  GetEventList,
  GetEventListSuccess,
  GetEventListError,
  AddExclusiveContentSuccess,
  AddExclusiveContentError,
  AddExclusiveContent,
  AddFanclubName,
  AddFanClubNameSuccess,
  AddUpdateDiscounts,
  AddUpdateDiscountsSuccess,
  AddUpdateDiscountsError,
  AddUpdateLiveShow,
  AddUpdateLiveShowSuccess,
  AddUpdateLiveShowError,
  GetSubscriptionPlansList,
  GetSubscriptionPlansListSuccess,
  GetSubscriptionPlansListError,
  UpdatePlan,
  UpdatePlanSuccess,
  UpdatePlanError,
  GetExclusiveContentList,
  GetExclusiveContentListSuccess,
  GetExclusiveContentListError,
  GetLiveShowsList,
  GetLiveShowsListSuccess,
  GetLiveShowsListError,
  GetDiscountList,
  GetDiscountListSuccess,
  GetDiscountListError,
  GetSubscriptionPlansListArtis,
  GetSubscriptionPlansListArtisSuccess,
  GetSubscriptionPlansListArtisError,
  DeleteContent,
  DeleteContentSuccess,
  DeleteContentError,
  AddUpdateMerchandising,
  AddUpdateMerchandisingSuccess,
  AddUpdateMerchandisingError,
  GetMerchandisingUrl,
  GetMerchandisingUrlSuccess,
  GetMerchandisingUrlError,
  GetBillingDetails,
  GetBillingDetailsSuccess,
  GetBillingDetailsError,
  GenerateRtmToken,
  GenerateRtmTokenSuccess,
  GenerateRtmTokenError,
  GetArtistDashboard,
  GetArtistDashboardSuccess,
  GetArtistDashboardError,
  DeleteMerchandise,
  DeleteMerchandiseSuccess, DeleteMerchandiseError,
  AddUpdateRadioStation,
  AddUpdateRadioStationError,
  AddUpdateRadioStationSuccess,
  DeleteRadioStation, DeleteRadioStationError,
  DeleteRadioStationSuccess,
  GetAllRadioStations,
  GetAllRadioStationsError,
  GetAllRadioStationsSuccess,
  GetRadioStationById,
  GetRadioStationByIdError,
  GetRadioStationByIdSuccess
} from './artist.actions';
import { ResetAuthState } from '@frontend/auth-store';

@Injectable()
export class ArtistEffects {
  constructor(
    private actions$: Actions,
    private artistService: ArtistService,
    private loaderService: LoaderService,
    private ls: LocalstorageService
  ) {}

  dashboard$ = createEffect(() => this.actions$.pipe(
    ofType(GetArtistDashboard),
    switchMap((action) => {
      this.loaderService.show();
      return this.artistService.getArtistDashBoard(action.params).pipe(
        map((resp: any) => {
          this.loaderService.hide();
          if (resp.success) {
            return GetArtistDashboardSuccess({dashboard: resp.data});
          }
          return GetArtistDashboardError({error: this.loaderService.getErrorMessage(resp)});
        }),
        catchError(error => {
          this.loaderService.hide();
          return of(GetArtistDashboardError({error: this.loaderService.getErrorMessage(error)}));
        })
      );
    })
  ))

  GetBillingDetails$ = createEffect(() =>
  this.actions$.pipe(
    ofType(GetBillingDetails),
    switchMap((action) => {
      this.loaderService.show();
      return this.artistService.getBillingDetails(action.params).pipe(
        map((resp: any) => {
          this.loaderService.hide();
          if (resp.success) {
            let empty = null;
            if (action.params.sendEmpty) {
              empty = { data: [], count: { total: 0, page: 0 } };
            }
            return GetBillingDetailsSuccess({
              billingDetails: resp.data || empty,
            });
          }
          return GetBillingDetailsError({
            error: this.loaderService.getErrorMessage(resp),
          });
        }),
        catchError((error) => {
          this.loaderService.hide();
          return of(
            GetBillingDetailsError({
              error: this.loaderService.getErrorMessage(error),
            })
          );
        })
      );
    })
  )
);

  GetMerchandisingUrl$ = createEffect(() =>
  this.actions$.pipe(
    ofType(GetMerchandisingUrl),
    switchMap((action) => {
      this.loaderService.show();
      return this.artistService.getUrlsData(action.params).pipe(
        map((resp: any) => {
          this.loaderService.hide();
          if (resp.success) {
            let empty = null;
            if (action.params.sendEmpty) {
              empty = { data: [], count: { total: 0, page: 0 } };
            }
            return GetMerchandisingUrlSuccess({
              urlslist: resp.data || empty,
            });
          }
          return GetMerchandisingUrlError({
            error: this.loaderService.getErrorMessage(resp),
          });
        }),
        catchError((error) => {
          this.loaderService.hide();
          return of(
            GetMerchandisingUrlError({
              error: this.loaderService.getErrorMessage(error),
            })
          );
        })
      );
    })
  )
);

  AddUpdateMerchandising$ = createEffect(() =>
  this.actions$.pipe(
    ofType(AddUpdateMerchandising),
    switchMap((action) => {
      this.loaderService.show();
      return this.artistService.addUpdateMerchandising(action.body,action.id).pipe(
        map((resp: any) => {
          this.loaderService.hide();
          if (resp.success) {
            return AddUpdateMerchandisingSuccess({ success: resp.data });
          }
          return AddUpdateMerchandisingError({
            error: this.loaderService.getErrorMessage(resp),
          });
        }),
        catchError((error) => {
          this.loaderService.hide();
          return of(
            AddUpdateMerchandisingError({
              error: this.loaderService.getErrorMessage(error),
            })
          );
        })
      );
    })
  )
);

  DeleteContent$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DeleteContent),
      switchMap((action) => {
        this.loaderService.show();
        return this.artistService.deleteContent(action.content_id,action.data_id,action.fileType).pipe(
          map((resp: any) => {
            this.loaderService.hide();
            if (resp.success) {
              return DeleteContentSuccess();
            }
            return DeleteContentError({
              error: this.loaderService.getErrorMessage(resp),
            });
          }),
          catchError((error) => {
            this.loaderService.hide();
            return of(
              DeleteContentError({
                error: this.loaderService.getErrorMessage(error),
              })
            );
          })
        );
      })
    )
  );

  GetSubscriptionPlansListArtis$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GetSubscriptionPlansListArtis),
      switchMap((action) => {
        this.loaderService.show();
        return this.artistService
          .getAllSubscriptionPlansArtis(action.params)
          .pipe(
            map((resp: any) => {
              this.loaderService.hide();
              if (resp.success) {
                const empty: any = { data: [], count: { total: 0, page: 0 } };

                return GetSubscriptionPlansListArtisSuccess({
                  artistplans: resp.data || empty,
                });
              }
              return GetSubscriptionPlansListArtisError({
                error: this.loaderService.getErrorMessage(resp),
              });
            }),
            catchError((error) => {
              this.loaderService.hide();
              return of(
                GetSubscriptionPlansListArtisError({
                  error: this.loaderService.getErrorMessage(error),
                })
              );
            })
          );
      })
    )
  );
  GetDiscountList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GetDiscountList),
      switchMap((action) => {
        this.loaderService.show();
        return this.artistService.getAllDiscounts(action.params).pipe(
          map((resp: any) => {
            this.loaderService.hide();
            if (resp.success) {
              let empty = null;
              if (action.params.sendEmpty) {
                empty = { data: [], count: { total: 0, page: 0 } };
              }
              return GetDiscountListSuccess({
                discountlist: resp.data || empty,
              });
            }
            return GetDiscountListError({
              error: this.loaderService.getErrorMessage(resp),
            });
          }),
          catchError((error) => {
            this.loaderService.hide();
            return of(
              GetDiscountListError({
                error: this.loaderService.getErrorMessage(error),
              })
            );
          })
        );
      })
    )
  );

  GetLiveShowsList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GetLiveShowsList),
      switchMap((action) => {
        this.loaderService.show();
        return this.artistService.getAllLiveShows(action.params).pipe(
          map((resp: any) => {
            this.loaderService.hide();
            if (resp.success) {
              let empty = null;
              if (action.params.sendEmpty) {
                empty = { data: [], count: { total: 0, page: 0 } };
              }
              return GetLiveShowsListSuccess({ showslist: resp.data || empty });
            }
            return GetLiveShowsListError({
              error: this.loaderService.getErrorMessage(resp),
            });
          }),
          catchError((error) => {
            this.loaderService.hide();
            return of(
              GetLiveShowsListError({
                error: this.loaderService.getErrorMessage(error),
              })
            );
          })
        );
      })
    )
  );

  GetExclusiveContentList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GetExclusiveContentList),
      switchMap((action) => {
        this.loaderService.show();
        return this.artistService.getAllExclusiveContent(action.params).pipe(
          map((resp: any) => {
            this.loaderService.hide();
            if (resp.success) {
              let empty = null;
              if (action.params.sendEmpty) {
                empty = { data: [], count: { total: 0, page: 0 } };
              }
              return GetExclusiveContentListSuccess({
                contentlist: resp.data || empty,
              });
            }
            return GetExclusiveContentListError({
              error: this.loaderService.getErrorMessage(resp),
            });
          }),
          catchError((error) => {
            this.loaderService.hide();
            return of(
              GetExclusiveContentListError({
                error: this.loaderService.getErrorMessage(error),
              })
            );
          })
        );
      })
    )
  );

  UpdatePlan$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UpdatePlan),
      switchMap((action) => {
        this.loaderService.show();
        return this.artistService.updatePlan(action.body).pipe(
          map((resp: any) => {
            this.loaderService.hide();
            if (resp.success) {
              return UpdatePlanSuccess({ success: resp.data });
            }
            return UpdatePlanError({
              error: this.loaderService.getErrorMessage(resp),
            });
          }),
          catchError((error) => {
            this.loaderService.hide();
            return of(
              UpdatePlanError({
                error: this.loaderService.getErrorMessage(error),
              })
            );
          })
        );
      })
    )
  );

  GetSubscriptionPlansList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GetSubscriptionPlansList),
      switchMap((action) => {
        this.loaderService.show();
        return this.artistService.getAllSubscriptionPlans(action.params).pipe(
          map((resp: any) => {
            this.loaderService.hide();
            if (resp.success) {
              let empty = null;
              if (action.params.sendEmpty) {
                empty = { data: [], count: { total: 0, page: 0 } };
              }
              return GetSubscriptionPlansListSuccess({
                plans: resp.data || empty,
              });
            }
            return GetSubscriptionPlansListError({
              error: this.loaderService.getErrorMessage(resp),
            });
          }),
          catchError((error) => {
            this.loaderService.hide();
            return of(
              GetSubscriptionPlansListError({
                error: this.loaderService.getErrorMessage(error),
              })
            );
          })
        );
      })
    )
  );

  AddUpdateLiveShow$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AddUpdateLiveShow),
      switchMap((action) => {
        this.loaderService.show();
        return this.artistService.addUpdateLiveShows(action.body).pipe(
          map((resp: any) => {
            this.loaderService.hide();
            if (resp.success) {
              return AddUpdateLiveShowSuccess({ liveShows: resp.data });
            }
            return AddUpdateLiveShowError({
              error: this.loaderService.getErrorMessage(resp),
            });
          }),
          catchError((error) => {
            this.loaderService.hide();
            return of(
              AddUpdateLiveShowError({
                error: this.loaderService.getErrorMessage(error),
              })
            );
          })
        );
      })
    )
  );

  AddUpdateDiscounts$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AddUpdateDiscounts),
      switchMap((action) => {
        this.loaderService.show();
        return this.artistService.addUpdateDiscounts(action.body).pipe(
          map((resp: any) => {
            this.loaderService.hide();
            if (resp.success) {
              return AddUpdateDiscountsSuccess({ discounts: resp.data });
            }
            return AddUpdateDiscountsError({
              error: this.loaderService.getErrorMessage(resp),
            });
          }),
          catchError((error) => {
            this.loaderService.hide();
            return of(
              AddFanClubError({
                error: this.loaderService.getErrorMessage(error),
              })
            );
          })
        );
      })
    )
  );

  addUpdateFanClub$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AddFanClub),
      switchMap((action) => {
        this.loaderService.show();
        return this.artistService.addUpdateClub(action.body).pipe(
          map((resp: any) => {
            this.loaderService.hide();
            if (resp.success) {
              return AddFanClubSuccess({ fanClub: resp.data });
            }
            return AddFanClubError({
              error: this.loaderService.getErrorMessage(resp),
            });
          }),
          catchError((error) => {
            this.loaderService.hide();
            return of(
              AddFanClubError({
                error: this.loaderService.getErrorMessage(error),
              })
            );
          })
        );
      })
    )
  );

  addExclusoveContent$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AddExclusiveContent),
      switchMap((action) => {
        this.loaderService.show();
        return this.artistService.addExclusiveContent(action.body,action.content_id, action.data_id,action.dataType).pipe(
          map((resp: any) => {
            this.loaderService.hide();
            if (resp.success) {
              return AddExclusiveContentSuccess({
                exclusiveContent: resp.data,
              });
            }
            return AddExclusiveContentError({
              error: this.loaderService.getErrorMessage(resp),
            });
          }),
          catchError((error) => {
            this.loaderService.hide();
            return of(
              AddFanClubError({
                error: this.loaderService.getErrorMessage(error),
              })
            );
          })
        );
      })
    )
  );
  addFanclubName$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AddFanclubName),
      switchMap((action) => {
        this.loaderService.show();
        return this.artistService.addFanclubName(action.body).pipe(
          map((resp: any) => {
            this.loaderService.hide();
            if (resp.success) {
              return AddFanClubNameSuccess({ fanClub: resp.data });
            }
            return AddFanClubError({
              error: this.loaderService.getErrorMessage(resp),
            });
          }),
          catchError((error) => {
            this.loaderService.hide();
            return of(
              AddFanClubError({
                error: this.loaderService.getErrorMessage(error),
              })
            );
          })
        );
      })
    )
  );

  getEvents$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GetEventList),
      switchMap((action) => {
        this.loaderService.show();
        return this.artistService.getEvents(action.params).pipe(
          map((resp: any) => {
            this.loaderService.hide();
            if (resp.success) {
              let empty = null;
              if (action.params.sendEmpty) {
                empty = { data: [], count: { total: 0, page: 0 } };
              }
              return GetEventListSuccess({ events: resp.data || empty });
            }
            return GetEventListError({
              error: this.loaderService.getErrorMessage(resp),
            });
          }),
          catchError((error) => {
            this.loaderService.hide();
            return of(
              GetMusicListError({
                error: this.loaderService.getErrorMessage(error),
              })
            );
          })
        );
      })
    )
  );

  getProfile$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GetArtistProfile),
      switchMap((action) => {
        this.loaderService.show();
        return this.artistService
          .getArtistProfile(action.id, action.query)
          .pipe(
            map((resp: any) => {
              this.loaderService.hide();
              if (resp.success) {
                return GetArtistProfileSuccess({ artist: resp.data });
              }
              return GetArtistProfileError({
                error: this.loaderService.getErrorMessage(resp),
              });
            }),
            catchError((error) => {
              this.loaderService.hide();
              return of(
                GetArtistProfileError({
                  error: this.loaderService.getErrorMessage(error),
                })
              );
            })
          );
      })
    )
  );

  setup$ = createEffect(() =>
    this.actions$.pipe(
      ofType(StudioSetup),
      switchMap((action) => {
        this.loaderService.show();
        return this.artistService.studioSetup(action.body).pipe(
          map((resp: any) => {
            this.loaderService.hide();
            if (resp.success) {
              let user = this.ls.updateUserKey('get');
              if (user) {
                user = {
                  ...user,
                  stdio_status: resp.data.stdio_status,
                  category: resp.data.category,
                  role: resp.data.role || '',
                };
                this.ls.updateUserKey('store', user);
              }
              return StudioSetupSuccess({ artist: resp.data });
            }
            return StudioSetupError({
              error: this.loaderService.getErrorMessage(resp),
            });
          }),
          catchError((error) => {
            this.loaderService.hide();
            return of(
              StudioSetupError({
                error: this.loaderService.getErrorMessage(error),
              })
            );
          })
        );
      })
    )
  );

  isNameAvailable$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CheckNameAvailability),
      switchMap((action) => {
        this.loaderService.show();
        return this.artistService
          .nameValidity({ artist_name: action.name })
          .pipe(
            map((resp: any) => {
              this.loaderService.hide();
              if (resp.success) {
                return CheckNameAvailabilitySuccess({
                  isNameAvailable: 'available',
                });
              }
              return CheckNameAvailabilityError({
                error: this.loaderService.getErrorMessage(resp),
                isNameAvailable: 'un-available',
              });
            }),
            catchError((error) => {
              this.loaderService.hide();
              return of(
                CheckNameAvailabilityError({
                  error: this.loaderService.getErrorMessage(error),
                  isNameAvailable: 'un-available',
                })
              );
            })
          );
      })
    )
  );

  getPodcasts$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GetPodcastList),
      switchMap((action) => {
        this.loaderService.show();
        return this.artistService.getPodcasts(action.params).pipe(
          map((resp: any) => {
            this.loaderService.hide();
            if (resp.success) {
              let empty = null;
              if (action.params.sendEmpty) {
                empty = { data: [], count: { total: 0, page: 0 } };
              }
              return GetPodcastListSuccess({ podcasts: resp.data || empty });
            }
            return GetPodcastListError({
              error: this.loaderService.getErrorMessage(resp),
            });
          }),
          catchError((error) => {
            this.loaderService.hide();
            return of(
              GetPodcastListError({
                error: this.loaderService.getErrorMessage(error),
              })
            );
          })
        );
      })
    )
  );

  getAllCategory$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GetAllCategory),
      switchMap((action) => {
        this.loaderService.show();
        return this.artistService.getAllCategories(action.params).pipe(
          map((resp: any) => {
            this.loaderService.hide();
            if (resp.success) {
              return GetAllCategorySuccess({ categories: resp.data });
            }
            return GetAllCategoryError({
              error: this.loaderService.getErrorMessage(resp),
            });
          }),
          catchError((error) => {
            this.loaderService.hide();
            return of(
              GetAllCategoryError({
                error: this.loaderService.getErrorMessage(error),
              })
            );
          })
        );
      })
    )
  );

  addPodcast$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AddUpdatePodcast),
      switchMap((action) => {
        this.loaderService.show();
        return this.artistService.addUpdatePodcast(action.body).pipe(
          map((resp: any) => {
            this.loaderService.hide();
            if (resp.success) {
              return AddUpdatePodcastSuccess({ podcast: resp.data });
            }
            return AddUpdatePodcastError({
              error: this.loaderService.getErrorMessage(resp),
            });
          }),
          catchError((error) => {
            this.loaderService.hide();
            return of(
              AddUpdatePodcastError({
                error: this.loaderService.getErrorMessage(error),
              })
            );
          })
        );
      })
    )
  );

  getPodcastEpisode$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GetPodcastEpisodeList),
      switchMap((action) => {
        this.loaderService.show();
        return this.artistService.getPodcastEpisodes(action.podcast_id, action.isForWeb).pipe(
          map((resp: any) => {
            this.loaderService.hide();
            if (resp.success) {
              return GetPodcastEpisodeListSuccess({
                episodes: resp.data?.data || [],
              });
            }
            return GetPodcastEpisodeListError({
              error: this.loaderService.getErrorMessage(resp),
            });
          }),
          catchError((error) => {
            this.loaderService.hide();
            return of(
              GetPodcastEpisodeListError({
                error: this.loaderService.getErrorMessage(error),
              })
            );
          })
        );
      })
    )
  );

  deletePodcast$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DeletePodcast),
      switchMap((action) => {
        this.loaderService.show();
        return this.artistService.deletePodcast(action.podcast_id).pipe(
          map((resp: any) => {
            this.loaderService.hide();
            if (resp.success) {
              return DeletePodcastSuccess();
            }
            return DeletePodcastError({
              error: this.loaderService.getErrorMessage(resp),
            });
          }),
          catchError((error) => {
            this.loaderService.hide();
            return of(
              DeletePodcastError({
                error: this.loaderService.getErrorMessage(error),
              })
            );
          })
        );
      })
    )
  );

  deleteEpisode$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DeleteEpisode),
      switchMap((action) => {
        this.loaderService.show();
        return this.artistService.deleteEpisode(action.episode_id).pipe(
          map((resp: any) => {
            this.loaderService.hide();
            if (resp.success) {
              return DeleteEpisodeSuccess();
            }
            return DeleteEpisodeError({
              error: this.loaderService.getErrorMessage(resp),
            });
          }),
          catchError((error) => {
            this.loaderService.hide();
            return of(
              DeleteEpisodeError({
                error: this.loaderService.getErrorMessage(error),
              })
            );
          })
        );
      })
    )
  );

  getPodcastById$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GetPodcastById),
      switchMap((action) => {
        this.loaderService.show();
        return this.artistService
          .getPodcastById(action.podcast_id, action.query)
          .pipe(
            map((resp: any) => {
              this.loaderService.hide();
              if (resp.success) {
                return GetPodcastByIdSuccess({ podcast: resp.data });
              }
              return GetPodcastByIdError({
                error: this.loaderService.getErrorMessage(resp),
              });
            }),
            catchError((error) => {
              this.loaderService.hide();
              return of(
                GetPodcastByIdError({
                  error: this.loaderService.getErrorMessage(error),
                })
              );
            })
          );
      })
    )
  );

  getEpisodeById$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GetEpisodeById),
      switchMap((action) => {
        this.loaderService.show();
        return this.artistService
          .getEpisodeById(action.podcast_id, action.episode_id, action.query)
          .pipe(
            map((resp: any) => {
              this.loaderService.hide();
              if (resp.success) {
                return GetEpisodeByIdSuccess({ episode: resp.data });
              }
              return GetEpisodeByIdError({
                error: this.loaderService.getErrorMessage(resp),
              });
            }),
            catchError((error) => {
              this.loaderService.hide();
              return of(
                GetPodcastByIdError({
                  error: this.loaderService.getErrorMessage(error),
                })
              );
            })
          );
      })
    )
  );

  addUpdateEpisode$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AddUpdateEpisode),
      switchMap((action) => {
        this.loaderService.show();
        return this.artistService.addUpdateEpisode(action.body).pipe(
          map((resp: any) => {
            this.loaderService.hide();
            if (resp.success) {
              return AddUpdateEpisodeSuccess({ podcast: resp.data });
            }
            return AddUpdateEpisodeError({
              error: this.loaderService.getErrorMessage(resp),
            });
          }),
          catchError((error) => {
            this.loaderService.hide();
            return of(
              AddUpdateEpisodeError({
                error: this.loaderService.getErrorMessage(error),
              })
            );
          })
        );
      })
    )
  );

  addAllUpdateEpisode$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AddUpdateAllEpisode),
      switchMap((action) => {
        this.loaderService.show();
        return this.artistService.addUpdateAllEpisode(action.body).pipe(
          map((resp: any) => {
            this.loaderService.hide();
            if (resp.success) {
              return AddUpdateAllEpisodeSuccess({ podcast: resp.data });
            }
            return AddUpdateAllEpisodeError({
              error: this.loaderService.getErrorMessage(resp),
            });
          }),
          catchError((error) => {
            this.loaderService.hide();
            return of(
              AddUpdateAllEpisodeError({
                error: this.loaderService.getErrorMessage(error),
              })
            );
          })
        );
      })
    )
  );

  updatePodcastTerms$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UpdatePodcastTerms),
      switchMap((action) => {
        this.loaderService.show();
        return this.artistService.updatePodcastTerms(action.body).pipe(
          map((resp: any) => {
            this.loaderService.hide();
            if (resp.success) {
              return UpdatePodcastTermsSuccess();
            }
            return UpdatePodcastTermsError({
              error: this.loaderService.getErrorMessage(resp),
            });
          }),
          catchError((error) => {
            this.loaderService.hide();
            return of(
              UpdatePodcastTermsError({
                error: this.loaderService.getErrorMessage(error),
              })
            );
          })
        );
      })
    )
  );

  getSkits$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GetSkitsList),
      switchMap((action) => {
        this.loaderService.show();
        return this.artistService.getSkits(action.params).pipe(
          map((resp: any) => {
            this.loaderService.hide();
            if (resp.success) {
              let empty = null;
              if (action.params.sendEmpty) {
                empty = { data: [], count: { total: 0, page: 0 } };
              }
              return GetSkitsListSuccess({ skits: resp.data || empty });
            }
            return GetSkitsListError({
              error: this.loaderService.getErrorMessage(resp),
            });
          }),
          catchError((error) => {
            this.loaderService.hide();
            return of(
              GetSkitsListError({
                error: this.loaderService.getErrorMessage(error),
              })
            );
          })
        );
      })
    )
  );

  deleteSkits$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DeleteSkit),
      switchMap((action) => {
        this.loaderService.show();
        return this.artistService.deleteSkit(action.skit_id).pipe(
          map((resp: any) => {
            this.loaderService.hide();
            if (resp.success) {
              return DeleteSkitSuccess();
            }
            return DeleteSkitError({
              error: this.loaderService.getErrorMessage(resp),
            });
          }),
          catchError((error) => {
            this.loaderService.hide();
            return of(
              DeleteSkitError({
                error: this.loaderService.getErrorMessage(error),
              })
            );
          })
        );
      })
    )
  );

  addUpdateSkits$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AddUpdateSkit),
      switchMap((action) => {
        this.loaderService.show();
        return this.artistService.addUpdateSkit(action.body).pipe(
          map((resp: any) => {
            this.loaderService.hide();
            if (resp.success) {
              return AddUpdateSkitSuccess({ skit: resp.data });
            }
            return AddUpdateSkitError({
              error: this.loaderService.getErrorMessage(resp),
            });
          }),
          catchError((error) => {
            console.log('error from skit add', error)
            this.loaderService.hide();
            return of(
              AddUpdateSkitError({
                error: this.loaderService.getErrorMessage(error),
              })
            );
          })
        );
      })
    )
  );

  getSkitById$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GetSkitById),
      switchMap((action) => {
        this.loaderService.show();
        return this.artistService
          .getSkitById(action.skit_id, action.query)
          .pipe(
            map((resp: any) => {
              this.loaderService.hide();
              if (resp.success) {
                return GetSkitByIdSuccess({ skit: resp.data });
              }
              return GetSkitByIdError({
                error: this.loaderService.getErrorMessage(resp),
              });
            }),
            catchError((error) => {
              this.loaderService.hide();
              return of(
                GetSkitByIdError({
                  error: this.loaderService.getErrorMessage(error),
                })
              );
            })
          );
      })
    )
  );

  updateSkitsTerms$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UpdateSkitsTerms),
      switchMap((action) => {
        this.loaderService.show();
        return this.artistService.updateSkitsTerms(action.body).pipe(
          map((resp: any) => {
            this.loaderService.hide();
            if (resp.success) {
              return UpdateSkitsTermsSuccess();
            }
            return UpdateSkitsTermsError({
              error: this.loaderService.getErrorMessage(resp),
            });
          }),
          catchError((error) => {
            this.loaderService.hide();
            return of(
              UpdateSkitsTermsError({
                error: this.loaderService.getErrorMessage(error),
              })
            );
          })
        );
      })
    )
  );

  updateArtist$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UpdateArtist),
      switchMap((action) => {
        this.loaderService.show();
        return this.artistService.updateArtist(action.id, action.body).pipe(
          map((resp: any) => {
            this.loaderService.hide();
            if (resp.success) {
              let user = this.ls.updateUserKey('get');
              user = {
                ...user,
                category: resp.data.category,
                role: resp.data.role || '',
              };
              this.ls.updateUserKey('store', user);
              ResetAuthState({ params: { loginUser: user } });
              return UpdateArtistSuccess({ artist: resp.data });
            }
            return UpdateArtistError({
              error: this.loaderService.getErrorMessage(resp),
            });
          }),
          catchError((error) => {
            this.loaderService.hide();
            return of(
              UpdateArtistError({
                error: this.loaderService.getErrorMessage(error),
              })
            );
          })
        );
      })
    )
  );

  verifyPhoneEmail$ = createEffect(() =>
    this.actions$.pipe(
      ofType(VerifyEmailPhone),
      switchMap((action) => {
        this.loaderService.show();
        return this.artistService.verifyPhoneEmail(action.body).pipe(
          map((resp: any) => {
            this.loaderService.hide();
            if (resp.success) {
              return VerifyEmailPhoneSuccess();
            }
            return VerifyEmailPhoneError({
              error: this.loaderService.getErrorMessage(resp),
            });
          }),
          catchError((error) => {
            this.loaderService.hide();
            return of(
              VerifyEmailPhoneError({
                error: this.loaderService.getErrorMessage(error),
              })
            );
          })
        );
      })
    )
  );

  updatePassword$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UpdatePassword),
      switchMap((action) => {
        this.loaderService.show();
        return this.artistService.updatePassword(action.body).pipe(
          map((resp: any) => {
            this.loaderService.hide();
            if (resp.success) {
              return UpdatePasswordSuccess();
            }
            return UpdatePasswordError({
              error: this.loaderService.getErrorMessage(resp),
            });
          }),
          catchError((error) => {
            this.loaderService.hide();
            return of(
              UpdatePasswordError({
                error: this.loaderService.getErrorMessage(error),
              })
            );
          })
        );
      })
    )
  );

  deleteProfile$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DeleteProfile),
      switchMap((action) => {
        this.loaderService.show();
        return this.artistService.deleteProfile(action.id).pipe(
          map((resp: any) => {
            this.loaderService.hide();
            if (resp.success) {
              return DeleteProfileSuccess();
            }
            return DeleteProfileError({
              error: this.loaderService.getErrorMessage(resp),
            });
          }),
          catchError((error) => {
            this.loaderService.hide();
            return of(
              DeleteProfileError({
                error: this.loaderService.getErrorMessage(error),
              })
            );
          })
        );
      })
    )
  );

  getMusics$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GetMusicList),
      switchMap((action) => {
        this.loaderService.show();
        return this.artistService.getMusics(action.params).pipe(
          map((resp: any) => {
            this.loaderService.hide();
            if (resp.success) {
              let empty = null;
              if (action.params.sendEmpty) {
                empty = { data: [], count: { total: 0, page: 0 } };
              }
              return GetMusicListSuccess({ musics: resp.data || empty });
            }
            return GetMusicListError({
              error: this.loaderService.getErrorMessage(resp),
            });
          }),
          catchError((error) => {
            this.loaderService.hide();
            return of(
              GetMusicListError({
                error: this.loaderService.getErrorMessage(error),
              })
            );
          })
        );
      })
    )
  );

  getAllGenres$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GetAllGenres),
      switchMap((action) => {
        this.loaderService.show();
        return this.artistService.getAllGenres(action).pipe(
          map((resp: any) => {
            this.loaderService.hide();
            if (resp.success) {
              return GetAllGenresSuccess({ genres: resp.data });
            }
            return GetAllGenresError({
              error: this.loaderService.getErrorMessage(resp),
            });
          }),
          catchError((error) => {
            this.loaderService.hide();
            return of(
              GetAllGenresError({
                error: this.loaderService.getErrorMessage(error),
              })
            );
          })
        );
      })
    )
  );

  addAlbum$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AddUpdateMusic),
      switchMap((action) => {
        this.loaderService.show();
        return this.artistService.addUpdateMusic(action.body).pipe(
          map((resp: any) => {
            this.loaderService.hide();
            if (resp.success) {
              return AddUpdateMusicSuccess({ music: resp.data });
            }
            return AddUpdateMusicError({
              error: this.loaderService.getErrorMessage(resp),
            });
          }),
          catchError((error) => {
            this.loaderService.hide();
            return of(
              AddUpdateMusicError({
                error: this.loaderService.getErrorMessage(error),
              })
            );
          })
        );
      })
    )
  );

  getMusicTrack$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GetMusicTrackList),
      switchMap((action) => {
        this.loaderService.show();
        return this.artistService
          .getMusicTracks(action.music_id, action.query)
          .pipe(
            map((resp: any) => {
              this.loaderService.hide();
              if (resp.success) {
                return GetMusicTrackListSuccess({
                  tracks: resp.data?.data || [],
                });
              }
              return GetMusicTrackListError({
                error: this.loaderService.getErrorMessage(resp),
              });
            }),
            catchError((error) => {
              this.loaderService.hide();
              return of(
                GetMusicTrackListError({
                  error: this.loaderService.getErrorMessage(error),
                })
              );
            })
          );
      })
    )
  );

  deleteAlbum$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DeleteMusic),
      switchMap((action) => {
        this.loaderService.show();
        return this.artistService.deleteMusic(action.music_id).pipe(
          map((resp: any) => {
            this.loaderService.hide();
            if (resp.success) {
              return DeleteMusicSuccess();
            }
            return DeleteMusicError({
              error: this.loaderService.getErrorMessage(resp),
            });
          }),
          catchError((error) => {
            this.loaderService.hide();
            return of(
              DeleteMusicError({
                error: this.loaderService.getErrorMessage(error),
              })
            );
          })
        );
      })
    )
  );

  deleteTrack$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DeleteTrack),
      switchMap((action) => {
        this.loaderService.show();
        return this.artistService.deleteTrack(action.track_id).pipe(
          map((resp: any) => {
            this.loaderService.hide();
            if (resp.success) {
              return DeleteTrackSuccess();
            }
            return DeleteTrackError({
              error: this.loaderService.getErrorMessage(resp),
            });
          }),
          catchError((error) => {
            this.loaderService.hide();
            return of(
              DeleteTrackError({
                error: this.loaderService.getErrorMessage(error),
              })
            );
          })
        );
      })
    )
  );

  getAlbumById$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GetMusicById),
      switchMap((action) => {
        this.loaderService.show();
        return this.artistService
          .getAlbumById(action.music_id, action.query)
          .pipe(
            map((resp: any) => {
              this.loaderService.hide();
              if (resp.success) {
                return GetMusicByIdSuccess({ music: resp.data });
              }
              return GetMusicByIdError({
                error: this.loaderService.getErrorMessage(resp),
              });
            }),
            catchError((error) => {
              this.loaderService.hide();
              return of(
                GetMusicByIdError({
                  error: this.loaderService.getErrorMessage(error),
                })
              );
            })
          );
      })
    )
  );

  getTrackById$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GetTrackById),
      switchMap((action) => {
        this.loaderService.show();
        return this.artistService
          .getTrackById(action.music_id, action.track_id, action.query)
          .pipe(
            map((resp: any) => {
              this.loaderService.hide();
              if (resp.success) {
                return GetTrackByIdSuccess({ track: resp.data });
              }
              return GetTrackByIdError({
                error: this.loaderService.getErrorMessage(resp),
              });
            }),
            catchError((error) => {
              this.loaderService.hide();
              return of(
                GetPodcastByIdError({
                  error: this.loaderService.getErrorMessage(error),
                })
              );
            })
          );
      })
    )
  );

  addUpdateTrack$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AddUpdateTrack),
      switchMap((action) => {
        this.loaderService.show();
        return this.artistService.addUpdateTrack(action.body).pipe(
          map((resp: any) => {
            this.loaderService.hide();
            if (resp.success) {
              return AddUpdateTrackSuccess({ music: resp.data });
            }
            return AddUpdateTrackError({
              error: this.loaderService.getErrorMessage(resp),
            });
          }),
          catchError((error) => {
            this.loaderService.hide();
            return of(
              AddUpdateTrackError({
                error: this.loaderService.getErrorMessage(error),
              })
            );
          })
        );
      })
    )
  );

  addAllUpdateTrack$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AddUpdateAllTracks),
      switchMap((action) => {
        this.loaderService.show();
        return this.artistService.addUpdateAllTracks(action.body).pipe(
          map((resp: any) => {
            this.loaderService.hide();
            if (resp.success) {
              return AddUpdateAllTracksSuccess({ music: resp.data });
            }
            return AddUpdateAllTracksError({
              error: this.loaderService.getErrorMessage(resp),
            });
          }),
          catchError((error) => {
            this.loaderService.hide();
            return of(
              AddUpdateAllTracksError({
                error: this.loaderService.getErrorMessage(error),
              })
            );
          })
        );
      })
    )
  );

  updateAlbumTerms$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UpdateMusicTerms),
      switchMap((action) => {
        this.loaderService.show();
        return this.artistService.updateAlbumTerms(action.body).pipe(
          map((resp: any) => {
            this.loaderService.hide();
            if (resp.success) {
              return UpdateMusicTermsSuccess();
            }
            return UpdateMusicTermsError({
              error: this.loaderService.getErrorMessage(resp),
            });
          }),
          catchError((error) => {
            this.loaderService.hide();
            return of(
              UpdateMusicTermsError({
                error: this.loaderService.getErrorMessage(error),
              })
            );
          })
        );
      })
    )
  );

  getAllBankDetail$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GetAllBankDetail),
      switchMap((action) => {
        this.loaderService.show();
        return this.artistService.getAllBankDetail().pipe(
          map((resp: any) => {
            this.loaderService.hide();
            if (resp.success) {
              return GetAllBankDetailSuccess({ bankDetails: resp.data });
            }
            return GetAllBankDetailError({
              error: this.loaderService.getErrorMessage(resp),
            });
          }),
          catchError((error) => {
            this.loaderService.hide();
            return of(
              GetAllBankDetailError({
                error: this.loaderService.getErrorMessage(error),
              })
            );
          })
        );
      })
    )
  );

  addUpdateBankDetail$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AddUpdateBankDetail),
      switchMap((action) => {
        this.loaderService.show();
        return this.artistService.addUpdateBankDetail(action.body).pipe(
          map((resp: any) => {
            this.loaderService.hide();
            if (resp.success) {
              return AddUpdateBankDetailSuccess({ bankDetail: resp.data });
            }
            return AddUpdateBankDetailError({
              error: this.loaderService.getErrorMessage(resp),
            });
          }),
          catchError((error) => {
            this.loaderService.hide();
            return of(
              AddUpdateBankDetailError({
                error: this.loaderService.getErrorMessage(error),
              })
            );
          })
        );
      })
    )
  );

  deleteBankDetail$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DeleteBankDetail),
      switchMap((action) => {
        this.loaderService.show();
        return this.artistService.deleteBankDetail(action.bankDetailId).pipe(
          map((resp: any) => {
            this.loaderService.hide();
            if (resp.success) {
              return DeleteBankDetailSuccess();
            }
            return DeleteBankDetailError({
              error: this.loaderService.getErrorMessage(resp),
            });
          }),
          catchError((error) => {
            this.loaderService.hide();
            return of(
              DeleteBankDetailError({
                error: this.loaderService.getErrorMessage(error),
              })
            );
          })
        );
      })
    )
  );

  GenerateRtmTokens$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GenerateRtmToken),
      switchMap((action) => {
        this.loaderService.show();
        return this.artistService.GenerateRtmTokens(action.params).pipe(
          map((resp: any) => {
            this.loaderService.hide();
            if (resp.success) {
              return GenerateRtmTokenSuccess({ rtmToken: resp.data });
            }
            return GenerateRtmTokenError({
              error: this.loaderService.getErrorMessage(resp),
            });
          }),
          catchError((error) => {
            this.loaderService.hide();
            return of(
              GenerateRtmTokenError({
                error: this.loaderService.getErrorMessage(error),
              })
            );
          })
        );
      })
    )
  );

  deleteMerchandise$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DeleteMerchandise),
      switchMap((action) => {
        this.loaderService.show();
        return this.artistService.removeMerchandise(action.merchandise_id).pipe(
          map((resp: any) => {
            this.loaderService.hide();
            if (resp.success) {
              return DeleteMerchandiseSuccess();
            }
            return DeleteMerchandiseError({
              error: this.loaderService.getErrorMessage(resp),
            });
          }),
          catchError((error) => {
            this.loaderService.hide();
            return of(
              DeleteMerchandiseError({
                error: this.loaderService.getErrorMessage(error),
              })
            );
          })
        );
      })
    )
  );

  getAllRadioStations$ = createEffect(() => this.actions$.pipe(
    ofType(GetAllRadioStations),
    switchMap((action) => {
      this.loaderService.show();
      return this.artistService.getAllRadioStations(action.params).pipe(
        map((resp: any) => {
          this.loaderService.hide();
          if (resp.success) {
            return GetAllRadioStationsSuccess({radio_stations: resp.data || {data: [], count: {total: 0, page: 0}}});
          }
          return GetAllRadioStationsError({error: this.loaderService.getErrorMessage(resp)});
        }),
        catchError((error) => {
          this.loaderService.hide();
          return of(GetAllRadioStationsError({error: this.loaderService.getErrorMessage(error)}));
        })
      );
    })
    )
  );

  getRadioStationById$ = createEffect(() => this.actions$.pipe(
    ofType(GetRadioStationById),
    switchMap((action) => {
      this.loaderService.show();
      return this.artistService.getRadioStationById(action.radio_station_id, action.query).pipe(
        map((resp: any) => {
          this.loaderService.hide();
          if (resp.success) {
            return GetRadioStationByIdSuccess({radio_station: resp.data});
          }
          return GetRadioStationByIdError({error: this.loaderService.getErrorMessage(resp)});
        }),
        catchError((error) => {
          this.loaderService.hide();
          return of(GetRadioStationByIdError({error: this.loaderService.getErrorMessage(error)}));
        })
      );
    })
    )
  );

  addUpdateRadioStation$ = createEffect(() => this.actions$.pipe(
    ofType(AddUpdateRadioStation),
    switchMap((action) => {
      this.loaderService.show();
      return this.artistService.addUpdateRadioStation(action.body).pipe(
        map((resp: any) => {
          this.loaderService.hide();
          if (resp.success) {
            return AddUpdateRadioStationSuccess({radio_station: resp.data});
          }
          return AddUpdateRadioStationError({error: this.loaderService.getErrorMessage(resp)});
        }),
        catchError((error) => {
          this.loaderService.hide();
          return of(AddUpdateRadioStationError({error: this.loaderService.getErrorMessage(error)}));
        })
      );
    })
    )
  );

  deleteRadioStation$ = createEffect(() => this.actions$.pipe(
    ofType(DeleteRadioStation),
    switchMap((action) => {
      this.loaderService.show();
      return this.artistService.deleteRadioStation(action.id).pipe(
        map((resp: any) => {
          this.loaderService.hide();
          if (resp.success) {
            return DeleteRadioStationSuccess({success: resp.message});
          }
          return DeleteRadioStationError({error: this.loaderService.getErrorMessage(resp)});
        }),
        catchError(error => {
          this.loaderService.hide();
          return of(DeleteRadioStationError({error: this.loaderService.getErrorMessage(error)}))
        })
      )
    })
  ))
}
