import { Component, OnInit } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { LoaderService } from '@frontend/app-config';
import { ArtistService, ArtistState, getArtist } from '@frontend/artist-store';
import { User } from '@frontend/data-models';
import { Store, select } from '@ngrx/store';
import { ToastrService } from 'ngx-toastr';
import { Subject, takeUntil } from 'rxjs';
import {BsModalRef} from "ngx-bootstrap/modal";
export interface Reason {
  label: string;
  value: string;
}
@Component({
  selector: 'frontend-helps',
  templateUrl: './helps.component.html',
  styleUrls: ['./helps.component.scss'],
})
export class HelpsComponent implements OnInit {
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  unsubscriber = new Subject();
  isModelShow: true
  reasonList: Reason[];
  contactForm: UntypedFormGroup;
  artist: User;
  constructor(
    private artistService: ArtistService,
    private toastrService: ToastrService,
    private loaderService: LoaderService,
    private formBuilder: UntypedFormBuilder,
    private artistStore: Store<ArtistState>,
    public bsModalRef: BsModalRef
  ) {}

  ngOnInit(): void {
    this.getReasonsList();
    this.subscribeToStore();
    this.contactForm = this.formBuilder.group({
      message: ['', Validators.required],
      reason: ['', Validators.required],
    });
  }
  subscribeToStore() {
    this.artistStore
      .pipe(select(getArtist))
      .pipe(takeUntil(this.unsubscriber))
      .subscribe((artist) => {
        if (artist) {
          this.artist = artist;
        }
      });
  }
  async getReasonsList() {
    this.loaderService.show();
    try {
      const res: any = await this.artistService.getReasons().toPromise();
      if (res?.data) {
        this.loaderService.hide();
        this.reasonList = res?.data.map((el: any) => ({
          label: el?.title,
          value: el?._id,
        }));
      }
    } catch (error: any) {
      this.toastrService.error(error || error.message);
    }
  }

  async saveContactDetails() {
    if (this.contactForm.invalid) {
      this.toastrService.error('Please Fill all the fiedls');
      return;
    }
    this.loaderService.show();
    const { message, reason } = this.contactForm.value;
    const body = {
      message,
      reason_id: reason,
      name: this.artist.artist_name,
      phone_number: this.artist?.phone_number.toString(),
      email: this.artist?.email,
    };
    try {
      const res = await this.artistService.postContactDetails(body).toPromise();
      if (res) {
        this.loaderService.hide();
        this.toastrService.success('Message Sent Sucessfully');
        this.contactForm = this.formBuilder.group({
          message: ['', Validators.required],
          reason: ['', Validators.required],
        });
      }
    } catch (error: any) {
      this.toastrService.error(error.error.message || error);
      this.loaderService.hide();
    }

    if (this.isModelShow) {
      this.bsModalRef.hide();

    }
  }

  redirectBack() {
    history.back();
  }
}
