import { Component, Input, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import {
  ArtistState,
  GetArtistProfile,
  getArtist,
} from '@frontend/artist-store';
import { AuthState, getLoggedInUser } from '@frontend/auth-store';
import { User } from '@frontend/data-models';
import { Store, select } from '@ngrx/store';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'frontend-side-bar',
  templateUrl: './side-bar.component.html',
  styleUrls: ['./side-bar.component.scss'],
})
export class SideBarComponent implements OnInit {
  currentRoute: string;
  unsubscriber = new Subject();

  @Input() currentUser: User;
  artist: User;
  currentPath: string;
  constructor(
    private router: Router,
    private artistStore: Store<ArtistState>,
    private authStore: Store<AuthState>
  ) {
    this.router.events.subscribe((event: any) => {
      if (event instanceof NavigationEnd) {
        const url = event.url.split('/').filter((t) => t);
        this.currentPath = event?.url;

        if (url[0]) {
          this.currentRoute =
            url[0].indexOf('?') !== -1
              ? url[0].substr(0, url[0].indexOf('?'))
              : url[0];
        } else {
          this.currentRoute = 'dashboard';
        }
      }
    });
  }
  ngOnInit(): void {
    if (!this.artist) {
      this.subscribeToStore();
    }
  }
  subscribeToStore() {
    this.authStore
      .pipe(select(getLoggedInUser))
      .pipe(takeUntil(this.unsubscriber))
      .subscribe((user) => {
        if (user) {
          this.artistStore.dispatch(
            GetArtistProfile({ id: user._id, query: { allFields: true } })
          );
        }
      });
    this.artistStore
      .pipe(select(getArtist))
      .pipe(takeUntil(this.unsubscriber))
      .subscribe((artist) => {
        if (artist) {
          this.artist = artist;
        }
      });
  }
  logoutUser() {
    localStorage.clear();
    // this.router.navigate(['sign-in']);
    window.location.href = '/sign-in';
  }
}
