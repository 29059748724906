import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {StoreModule} from "@ngrx/store";
import {EffectsModule} from "@ngrx/effects";
import * as fromArtist from "./+state/artist.reducer";
import {ArtistEffects} from "./+state/artist.effects";

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature('artist', fromArtist.reducer),
    EffectsModule.forFeature([ArtistEffects])
  ],
})
export class ArtistStoreModule {
}
