import { Component, Input } from '@angular/core';
import { User } from '@frontend/data-models';
import { AuthState, LogoutUser, getLoggedInUser } from '@frontend/auth-store';
import { LocalstorageService } from '@frontend/app-config';
import { Store, select } from '@ngrx/store';
import { NavigationEnd, Router } from '@angular/router';
import {
  ArtistState,
  GetArtistProfile,
  getArtist,
} from '@frontend/artist-store';
import { Subject, takeUntil } from 'rxjs';
import {BsModalService} from "ngx-bootstrap/modal";
import {HelpsComponent} from "../../../../components/helps/helps.component";

@Component({
  selector: 'frontend-verify-header',
  templateUrl: './verify-header.component.html',
  styleUrls: ['./verify-header.component.scss'],
})
export class VerifyHeaderComponent {
  @Input() currentUser: User;
  unsubscriber = new Subject();
  artist: User;
  currentPath: string;
  pathsToIgnore = [
    '/profile/edit/profile',
    '/music/add',
    '/music/edit',
    '/finance/edit-billing-detail',
    '/finance/edit-bank-detail',
    '/finance/purchase-token',
    '/fan-club/create',
    '/live-show/create-live-show',
    '/finance/payment-policy',
    '/radio/create-radio',
    '/radio/edit-radio'
  ];
  constructor(
    private router: Router,
    private authStore: Store<AuthState>,
    private ls: LocalstorageService,
    private artistStore: Store<ArtistState>,
    private modelService: BsModalService,

  ) {
    this.router.events.subscribe((event: any) => {
      if (event instanceof NavigationEnd) {
        this.currentPath =
          event?.url.indexOf('?') !== -1
            ? event?.url.substr(0, event.url.indexOf('?'))
            : event.url;
      }
    });
  }

  ngOnInit(): void {
    if (!this.artist) {
      this.subscribeToStore();
    }
  }

  subscribeToStore() {
    this.authStore
      .pipe(select(getLoggedInUser))
      .pipe(takeUntil(this.unsubscriber))
      .subscribe((user) => {
        if (user) {
          this.artistStore.dispatch(
            GetArtistProfile({ id: user._id, query: { allFields: true } })
          );
        }
      });
    this.artistStore
      .pipe(select(getArtist))
      .pipe(takeUntil(this.unsubscriber))
      .subscribe((artist) => {
        if (artist) {
          this.artist = artist;
        }
      });
  }

  logout() {
    this.artist = {};
    this.ls.clearAllLocalStorage();
    this.authStore.dispatch(LogoutUser());
    this.router.navigate(['/sign-in']);
  }

  openHelpModel() {
    this.modelService.show(HelpsComponent, {
      keyboard: true,
      animated: true,
      ignoreBackdropClick: false,
      class:
        'modal-lg modal-dialog-centered custom-model help-model',
      initialState: {
        isModelShow: true
      }
    });
  }
}
