<div class="body_bg">
  <div class="main_wrapper">
    <frontend-verify-header></frontend-verify-header>
    <frontend-side-bar></frontend-side-bar>

    <div class="main_content">
      <div class="row">
        <div class="col-12">
          <div class="icons_for_mobile_main">
            <div class="icons_for_mobile">
              <div class="icons_svg">
                <a href="#"><img src="assets/images/back_svg.svg" /></a>
                <a href="#"><img src="assets/images/live.svg"></a>
              </div>
              <div class="icons_svg">
                <a href="#" data-bs-toggle="modal" data-bs-target="#pause_video" ><img src="assets/images/pause_video.svg" /></a>
                <a href="#"  data-bs-toggle="modal" data-bs-target="#exampleModal" ><img src="assets/images/pause_music.svg"></a>
                <a href="#"  class="mr-0" data-bs-toggle="modal" data-bs-target="#setting_ic" ><img src="assets/images/settings_2.svg"/></a>
              </div>
            </div>
        </div>
          <div class="live_show_heading">
            <span><img src="assets/images/live.svg" /></span>
            <h1>Live show name goes here</h1>
          </div>
        </div>
      </div>
     
      <div class="row">
        <div class="col-md-12 col-lg-8">
          <div class="show_live_main">
            <div class="music_containers">
              <div class="music_img">
                <img src="assets/images/unsplashimg.png" />
              </div>
              <div class="show_main">
                <div class="m_timer">
                  <div class="timer">
                    <div class="time">
                      <span>0</span>
                      <span>0</span>
                    </div>
                    <p>DAYS</p>
                  </div>
                  <div class="dots">:</div>
                  <div class="timer">
                    <div class="time">
                      <span>0</span>
                      <span>0</span>
                    </div>
                    <p>HOURS</p>
                  </div>
                  <div class="dots">:</div>
                  <div class="timer">
                    <div class="time">
                      <span>0</span>
                      <span>0</span>
                    </div>
                    <p>MINUTES</p>
                  </div>
                </div>
                <div class="timer_des"><p>Your show will star soon. Do you have all you need?</p></div>
                <div class="start_btn"><button>Start</button></div>
              </div>
          </div>

          <div class="bottom_live_icon">
            <div class="m_icons">
              <div class="svg_ic" data-bs-toggle="modal" data-bs-target="#pause_video"><span><img src="assets/images/pause_video.svg"/></span></div>
              <div class="svg_ic" data-bs-toggle="modal" data-bs-target="#exampleModal"><span><img src="assets/images/pause_music.svg"  /></span></div>
              <div class="svg_ic" data-bs-toggle="modal" data-bs-target="#setting_ic"><span><img src="assets/images/settings_2.svg"/></span></div>
            </div>
            <div class="live_end">
              <div class="end_live_btn"><button>End live show</button></div>
              <div class="explore_ic"><a href="#"><img src="assets/images/expand.svg"/></a></div>
            </div>
          </div>
          </div>
        </div>
        <div class="col-md-12 col-lg-4">
          <div class="chat_view">
            <div class="people_joined">
                <span><img src="assets/images/people_group.svg"/></span>
                <p><b>5</b> people joined this show</p>
            </div>
            <div class="chat_area"></div>
            <div class="write_input">
              <input type="text" placeholder="Write here"/>
              <span><img src="assets/images/send.svg"/></span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>


<!-- Allow microphone Modal -->
<div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered model_mobile">
    <div class="modal-content">
      <div class="modal-body main_content_model">
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close">  <img src="assets/images/cros_pop.svg" /></button>
        <div class="radio_img">
          <img src="assets/images/unsplash_artist.png" />
        </div>
        <div class="mute_music_content">
          <h1>Allow Kwot to have access to your microphone?</h1>
          <p>This information will be saved for futures actions</p>
          <a href="javascript:;">Cancel access</a>
          <div class="allow_btn">
            <button>Allow</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>


<!-- Allow pausevideo Modal -->
<div class="modal fade" id="pause_video" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered model_mobile">
    <div class="modal-content">
      <div class="modal-body main_content_model">
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close">  <img src="assets/images/cros_pop.svg" /></button>
        <div class="radio_img">
          <img src="assets/images/video_mute.png" />
        </div>
        <div class="mute_music_content">
          <h1>Allow Kwot to have access to your camera?</h1>
          <p>This information will be saved for futures actions</p>
          <a href="javascript:;">Cancel access</a>
          <div class="allow_btn">
            <button>Allow</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>


<!-- setting Modal -->
<div class="modal fade" id="setting_ic" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered model_mobile">
    <div class="modal-content">
      <div class="modal-body setting_main">
        <div class="setting_main">
          <div class="setting_header">
            <h1>Settings</h1>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close">  <img src="assets/images/cros_pop.svg" /></button>
          </div>
          <div class="video_audio_section">
            <nav>
              <div class="nav nav-tabs" id="nav-tab" role="tablist">
                <button class="nav-link active" id="nav-home-tab" data-bs-toggle="tab" data-bs-target="#nav-home" type="button" role="tab" aria-controls="nav-home" aria-selected="true">Video</button>
                <button class="nav-link" id="nav-profile-tab" data-bs-toggle="tab" data-bs-target="#nav-profile" type="button" role="tab" aria-controls="nav-profile" aria-selected="false">Audio</button>
              </div>
            </nav>

            <div class="tab-content" id="nav-tabContent">
              <div class="tab-pane fade show active" id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab">
                 
                    <label class="toggle">
                      <input class="toggle-checkbox" type="checkbox" checked>
                      <div class="toggle-switch"></div>
                      <span class="toggle-label">Video allowed</span>
                    </label>
                  <div class="video_thumb">
                    <img src="assets/images/Video_01.png" />
                  </div>

                  <div class="camera_sec">
                    <label>Camera</label>
                    <select>
                      <option>Webcam Name HD</option>
                      <option>Webcam Name HD1</option>
                      <option>Webcam Name HD1</option>
                      <option>Webcam Name HD1</option>
                      <option>Webcam Name HD1</option>
                      <option>Webcam Name HD1</option>
                    </select>
                    <span> <img src="assets/images/Vector.png" /></span>
                  </div>

              </div>
              <div class="tab-pane fade" id="nav-profile" role="tabpanel" aria-labelledby="nav-profile-tab">
                <label class="toggle">
                  <input class="toggle-checkbox" type="checkbox" checked>
                  <div class="toggle-switch"></div>
                  <span class="toggle-label">Audio allowed</span>
                </label>
                  <div class="audio_fields">
                    <div class="select_field">
                    <label>Speaker</label>
                    <select>
                      <option>Integrated output (internal speakers)</option>
                      <option>Integrated output (internal speakers)1</option>
                      <option>Integrated output (internal speakers)2</option>
                      <option>Integrated output (internal speakers)3</option>
                    </select>
                    <span> <img src="assets/images/Vector.png" /></span>
                    </div>
                    <div class="level">
                      <p>Output level</p>
                      <ul>
                        <li class="active_li"></li>
                        <li class="active_li"></li>
                        <li class="active_li"></li>
                        <li class="active_li"></li>
                        <li class="active_li"></li>
                        <li class="active_li"></li>
                        <li class="active_li"></li>
                        <li class="active_li"></li>
                        <li class="active_li"></li>
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                    
                      </ul>
                    </div>
                  </div>


                  <div class="audio_fields">
                    <div class="select_field">
                    <label>Microphone</label>
                    <select>
                      <option>Build - in microphone (internal microphone)</option>
                      <option>Build - in microphone (internal microphone)1</option>
                      <option>Build - in microphone (internal microphone)1</option>
                    </select>
                    <span> <img src="assets/images/Vector.png" /></span>
                    </div>
                    <div class="level">
                      <p>Output level</p>
                      <ul>
                        <li class="active_li"></li>
                        <li class="active_li"></li>
                        <li class="active_li"></li>
                        <li class="active_li"></li>
                        <li class="active_li"></li>
                        <li class="active_li"></li>
                        <li class="active_li"></li>
                        <li class="active_li"></li>
                        <li class="active_li"></li>
                        <li class="active_li"></li>
                        <li class="active_li"></li>
                        <li class="active_li"></li>
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                        
                      </ul>
                    </div>
                  </div>


           
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>


