<div class="body_bg ht100">
    <!-- <frontend-profile-header step_1 = "true" ></frontend-profile-header> -->
    <section class="top_header_section">
        <div class="container-fluid">
            <div class="row">
                <div class="col-12" >
                    <div class="top_setting_main">
                        <div class="kwot_logo">
                            <img src="assets/images/Logo.png" />
                        </div>
                        <div class="category_list active_category">
                            1.General details
                        </div>
                        <div class="category_list ">
                            2. Checkboxes
                        </div>
                        
                        <div class="category_cross ">
                            <img src="assets/images/Cross1.png" />
                        </div>
                    </div> <div class="border_btm"></div>
                </div>
            </div>
        </div>
  </section>
    <section class="steps_section_main">
      <div class="row">
        <div class="col-md-4 col-lg-3 pr_0">
          <div class="type_artist">
           
            <div class="artsit_heading">
              <h1>Create live show</h1>
              <p>Please fill in the details below to add your live show.</p>
            </div>
          </div>
        </div>
        <div class="col-md-8 col-lg-9 pl_0">
          <div class="category_mains">
            <div class="skit_upload_main">
                <div class="row">
                    <div class="col-md-6">
                        <div class="upload_skit">
                                <div class="skit_heading_mobile">
                                    <h1>Create live show</h1>
                                    <p>Please fill in the details below to add your live show.</p>
                                </div>
                            
                           
                        </div>
                        <div class="skits_title">
                            <h1>Show title</h1>
                            <div class="skit_title_input">
                                <label>Show title</label>
                                <input type="text" placeholder="Live show title goes here"/>
                            </div>
                        </div>
                        
                        <div class="target_audiance">
                            <h1>Date & time</h1>
                           <div class="target_audiance_select position_date">
                                <label>Date</label>
                                <select>
                                    <option>MM/DD/YY</option>
                                </select>
                                <span>
                                    <img  src="assets/images/downa.svg">
                                </span>
                           </div> 
                           <div class="target_audiance_select position_date">
                            <label>Time</label>
                            <select>
                                <option>hh:mm</option>
                            </select>
                            <span>
                                <img  src="assets/images/downa.svg">
                            </span>
                       </div> 
                        </div>

                        <div class="explicit_content">
                            <h1>Explicit content?</h1>
                            <div class="explicit_content_radio">
                                <p>
                                    <input type="radio" id="test1" name="radio-group" checked class="radio">
                                    <label for="test1">Yes</label>
                                  </p>
                                  <p>
                                    <input type="radio" id="test2" name="radio-group" class="radio">
                                    <label for="test2">No</label>
                                  </p>
                                
                            </div>
                        </div>

                        <div class="target_audiance">
                            <h1>Target audience</h1>
                           <div class="target_audiance_select">
                                <select>
                                    <option>Select age</option>
                                </select>
                                <span>
                                    <img  src="assets/images/downa.svg">
                                </span>
                           </div> 
                        </div>

                        <div class="explicit_content">
                            <h1>Price</h1>
                            <div class="explicit_content_radio">
                                <p>
                                    <input type="radio" id="test3" name="radio-group" checked class="radio">
                                    <label for="test3">Free</label>
                                  </p>
                                  <p>
                                    <input type="radio" id="test4" name="radio-group" class="radio">
                                    <label for="test4">Paid</label>
                                  </p>
                                
                            </div>
                            <div class="price_input">
                                <label>Price</label>
                                <input type="text" placeholder="Price"/>
                             </div>
                        </div>

                        
                    </div>
                    <div class="col-md-6">
                       <div class="upload_skit_other_section">

                             <div class="lang_main">
                                <h1>Language</h1>
                                <div class="target_audiance_select">
                                    <label>Language</label>
                                    <select>
                                        <option>Select language</option>
                                    </select>
                                    <span>
                                        <img  src="assets/images/downa.svg">
                                    </span>
                               </div> 
                             </div>   

                             <div class="lang_main mt-3">
                                <h1>Category</h1>
                                <div class="target_audiance_select">
                                    <label>Category</label>
                                    <select>
                                        <option>Select category</option>
                                    </select>
                                    <span>
                                        <img  src="assets/images/downa.svg">
                                    </span>
                               </div> 
                             </div> 

                             <div class="cover_pic">
                                <h1>Cover picture</h1>
                                <p>jpj or png extension. Optimal is perfect square, optimal size is 3000x3000</p>
                                <div class="add_photo_sec">
                                    <input type="file" id="myfile" name="myfile">
                                    <img  src="assets/images/add_logo.png">
                                </div>
                             </div>

                       </div>
                    </div>
                </div>
            </div>
           

            <div class="back_continue">
              <div class="back_icon">
                <!-- <img src="assets/images/back_vector.png" /> -->
              </div>
              <div class="continue_btn">
                <button>Save</button>
              </div>
            </div>
            <div class="bottom_steps">
              <div class="steps_count">
                <p>Step 1 of 3</p>
                <button>Continue</button>
              </div>
            </div>
          </div>
        </div>
      </div>
  </section>
  </div>