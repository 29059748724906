import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router} from '@angular/router';
import {map} from 'rxjs';
import {AuthState, getLoggedInUser} from "@frontend/auth-store";
import {select, Store} from "@ngrx/store";
import {LocalstorageService} from "@frontend/app-config";
import {User} from "@frontend/data-models";
import {ToastrService} from "ngx-toastr";

@Injectable({
  providedIn: 'root'
})
export class RoleGuard implements CanActivate {

  constructor(
    private router: Router,
    private authStore: Store<AuthState>,
    private ls: LocalstorageService,
    private toastr: ToastrService
  ) {
  }

  canActivate(route: ActivatedRouteSnapshot) {
    return this.authStore.pipe(select(getLoggedInUser)).pipe(
      map((currentUser: User) => {
        if (!currentUser) {
          this.router.navigate(['/sign-in']);
          return false;
        }
        currentUser = this.ls.updateUserKey('get');
        const roles = route.data.roles as Array<string>;
        if (currentUser && roles.includes(currentUser.category)) {
          return true;
        }
        this.toastr.warning('You are not allowed to access this page');
        this.router.navigate(['/dashboard']);
        return false;
      })
    )
  }
}
