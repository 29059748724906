<div class="loader1" *ngIf="(loader$ | async) || loadingRouteConfig">
  <!--  <img class="desktop-loader" src="/assets/images/loaders/desktop_modal.gif"/>-->
  <!--  <img class="mobile-loader" src="/assets/images/loaders/mobile_modal.gif"/>-->
  <div class="d-flex flex-column" style="width: 200px">
    <span class="loader mx-auto"></span>
    <div class="upload-progress" *ngIf="(uploader$ | async)">
      <div class="progress-item">
        <p class="text-center text-light">Uploading</p>
        <div class="progress">
          <div class="progress-bar" role="progressbar" [style]="{width: (progress$ | async) + '%'}"
               aria-valuenow="25" aria-valuemin="0"
               aria-valuemax="100">{{(progress$ | async) + '%'}}
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<ng-container *ngIf="!hideSideBarAndHeader.includes(url)">
  <frontend-verify-header [currentUser]="{}"></frontend-verify-header>
  <frontend-side-bar [currentUser]="{}"></frontend-side-bar>
</ng-container>
<router-outlet></router-outlet>
