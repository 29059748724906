import { createAction, props } from '@ngrx/store';
import {
  Category,
  Episode,
  Music,
  PaginatedMusic,
  PaginatedPodcast,
  PaginatedSkits,
  Podcast,
  Skits,
  Tracks,
  User,
  BankDetail,
  FanClub,
  EventResponse,
  ExclusiveContent,
  Discount,
  LiveShow,
  SubscriptionPlan,
  ExclusiveContentList,
  ShowsList,
  DiscountList,
  MERCAHANDISING,
  BillingDetails
} from '@frontend/data-models';

const ArtistActions = {
  GET_ARTIST_DASHBOARD: '[ARTIST] Artist Dashboard',
  GET_ARTIST_DASHBOARD_SUCCESS: '[ARTIST] Artist Dashboard Success',
  GET_ARTIST_DASHBOARD_ERROR: '[ARTIST] Artist Dashboard Error',

  GET_ARTIST_PROFILE: '[ARTIST] Artist Profile',
  GET_ARTIST_PROFILE_SUCCESS: '[ARTIST] Artist Profile Success',
  GET_ARTIST_PROFILE_ERROR: '[ARTIST] Artist Profile Error',

  SETUP_STUDIO: '[ARTIST] Studio Setup',
  SETUP_STUDIO_SUCCESS: '[ARTIST] Studio Setup Success',
  SETUP_STUDIO_ERROR: '[ARTIST] Studio Setup Error',

  CHECK_NAME_AVAILABILITY: '[ARTIST] Check name availability',
  CHECK_NAME_AVAILABILITY_SUCCESS: '[ARTIST] Check name availability Success',
  CHECK_NAME_AVAILABILITY_ERROR: '[ARTIST] Check name availability Error',

  GET_PODCAST_LIST: '[ARTIST] Get Podcast List',
  GET_PODCAST_LIST_SUCCESS: '[ARTIST] Get Podcast List Success',
  GET_PODCAST_LIST_ERROR: '[ARTIST] Get Podcast List Error',

  GET_MUSIC_LIST: '[ARTIST] Get Music List',
  GET_MUSIC_LIST_SUCCESS: '[ARTIST] Get Music List Success',
  GET_MUSIC_LIST_ERROR: '[ARTIST] Get Music List Error',

  GET_PODCAST_EPISODE_LIST: '[ARTIST] Get Podcast Episode List',
  GET_PODCAST_EPISODE_LIST_SUCCESS: '[ARTIST] Get Podcast Episode List Success',
  GET_PODCAST_EPISODE_LIST_ERROR: '[ARTIST] Get Podcast Episode List Error',

  GET_MUSIC_TRACK_LIST: '[ARTIST] Get Music Track List',
  GET_MUSIC_TRACK_LIST_SUCCESS: '[ARTIST] Get Music Track List Success',
  GET_MUSIC_TRACK_LIST_ERROR: '[ARTIST] Get Music Track List Error',

  DELETE_PODCAST: '[ARTIST] Delete Podcast',
  DELETE_PODCAST_SUCCESS: '[ARTIST] Delete Podcast Success',
  DELETE_PODCAST_ERROR: '[ARTIST] Delete Podcast Error',

  DELETE_MUSIC: '[ARTIST] Delete Music',
  DELETE_MUSIC_SUCCESS: '[ARTIST] Delete Music Success',
  DELETE_MUSIC_ERROR: '[ARTIST] Delete Music Error',

  DELETE_EPISODE: '[ARTIST] Delete Episode',
  DELETE_EPISODE_SUCCESS: '[ARTIST] Delete Episode Success',
  DELETE_EPISODE_ERROR: '[ARTIST] Delete Episode Error',

  DELETE_TRACK: '[ARTIST] Delete Track',
  DELETE_TRACK_SUCCESS: '[ARTIST] Delete Track Success',
  DELETE_TRACK_ERROR: '[ARTIST] Delete Track Error',

  GET_ALL_CATEGORY: '[ARTIST] Get All Category',
  GET_ALL_CATEGORY_SUCCESS: '[ARTIST] Get All Category Success',
  GET_ALL_CATEGORY_ERROR: '[ARTIST] Get All Category Error',

  GET_ALL_GENRE: '[ARTIST] Get All Genre',
  GET_ALL_GENRE_SUCCESS: '[ARTIST] Get All Genre Success',
  GET_ALL_GENRE_ERROR: '[ARTIST] Get All Genre Error',

  ADD_UPDATE_PODCAST: '[ARTIST] Add / Update Podcast',
  ADD_UPDATE_PODCAST_SUCCESS: '[ARTIST] Add / Update Podcast Success',
  ADD_UPDATE_PODCAST_ERROR: '[ARTIST] Add / Update Podcast Error',

  ADD_UPDATE_MUSIC: '[ARTIST] Add / Update Music',
  ADD_UPDATE_MUSIC_SUCCESS: '[ARTIST] Add / Update Music Success',
  ADD_UPDATE_MUSIC_ERROR: '[ARTIST] Add / Update Music Error',

  GET_PODCAST_BY_ID: '[ARTIST] Get podcast by id',
  GET_PODCAST_BY_ID_SUCCESS: '[ARTIST] Get podcast by id Success',
  GET_PODCAST_BY_ID_ERROR: '[ARTIST] Get podcast by id Error',

  GET_MUSIC_BY_ID: '[ARTIST] Get Music by id',
  GET_MUSIC_BY_ID_SUCCESS: '[ARTIST] Get Music by id Success',
  GET_MUSIC_BY_ID_ERROR: '[ARTIST] Get Music by id Error',

  GET_EPISODE_BY_ID: '[ARTIST] Get episode by id',
  GET_EPISODE_BY_ID_SUCCESS: '[ARTIST] Get episode by id Success',
  GET_EPISODE_BY_ID_ERROR: '[ARTIST] Get episode by id Error',

  GET_TRACK_BY_ID: '[ARTIST] Get track by id',
  GET_TRACK_BY_ID_SUCCESS: '[ARTIST] Get track by id Success',
  GET_TRACK_BY_ID_ERROR: '[ARTIST] Get track by id Error',

  ADD_UPDATE_EPISODE: '[ARTIST] Add / Update Episode',
  ADD_UPDATE_EPISODE_SUCCESS: '[ARTIST] Add / Update Episode Success',
  ADD_UPDATE_EPISODE_ERROR: '[ARTIST] Add / Update Episode Error',

  ADD_UPDATE_TRACK: '[ARTIST] Add / Update Track',
  ADD_UPDATE_TRACK_SUCCESS: '[ARTIST] Add / Update Track Success',
  ADD_UPDATE_TRACK_ERROR: '[ARTIST] Add / Update Track Error',

  ADD_UPDATE_ALL_EPISODE: '[ARTIST] Add / Update All Episode',
  ADD_UPDATE_ALL_EPISODE_SUCCESS: '[ARTIST] Add / Update All Episode Success',
  ADD_UPDATE_ALL_EPISODE_ERROR: '[ARTIST] Add / Update All Episode Error',

  ADD_UPDATE_ALL_TRACK: '[ARTIST] Add / Update All Track',
  ADD_UPDATE_ALL_TRACK_SUCCESS: '[ARTIST] Add / Update All Track Success',
  ADD_UPDATE_ALL_TRACK_ERROR: '[ARTIST] Add / Update All Track Error',

  UPDATE_PODCAST_TERMS: '[ARTIST] Update Podcast Terms',
  UPDATE_PODCAST_TERMS_SUCCESS: '[ARTIST] Update Podcast Terms Success',
  UPDATE_PODCAST_TERMS_ERROR: '[ARTIST] Update Podcast Terms Error',

  UPDATE_MUSIC_TERMS: '[ARTIST] Update Music Terms',
  UPDATE_MUSIC_TERMS_SUCCESS: '[ARTIST] Update Music Terms Success',
  UPDATE_MUSIC_TERMS_ERROR: '[ARTIST] Update Music Terms Error',

  GET_SKITS_LIST: '[ARTIST] Get Skits List',
  GET_SKITS_LIST_SUCCESS: '[ARTIST] Get Skits List Success',
  GET_SKITS_LIST_ERROR: '[ARTIST] Get Skits List Error',

  DELETE_SKIT: '[ARTIST] Delete Skit',
  DELETE_SKIT_SUCCESS: '[ARTIST] Delete Skit Success',
  DELETE_SKIT_ERROR: '[ARTIST] Delete Skit Error',

  ADD_UPDATE_SKIT: '[ARTIST] Add / Update Skit',
  ADD_UPDATE_SKIT_SUCCESS: '[ARTIST] Add / Update Skit Success',
  ADD_UPDATE_SKIT_ERROR: '[ARTIST] Add / Update Skit Error',

  GET_SKIT_BY_ID: '[ARTIST] Get skit by id',
  GET_SKIT_BY_ID_SUCCESS: '[ARTIST] Get skit by id Success',
  GET_SKIT_BY_ID_ERROR: '[ARTIST] Get skit by id Error',

  UPDATE_SKITS_TERMS: '[ARTIST] Update Skits Terms',
  UPDATE_SKITS_TERMS_SUCCESS: '[ARTIST] Update Skits Terms Success',
  UPDATE_SKITS_TERMS_ERROR: '[ARTIST] Update Skits Terms Error',

  UPDATE_ARTIST: '[ARTIST] Update Artist',
  UPDATE_ARTIST_SUCCESS: '[ARTIST] Update Artist Success',
  UPDATE_ARTIST_ERROR: '[ARTIST] Update Artist Error',

  VERIFY_EMAIL_PHONE: '[ARTIST] Verify Email Phone',
  VERIFY_EMAIL_PHONE_SUCCESS: '[ARTIST] Verify Email Phone Success',
  VERIFY_EMAIL_PHONE_ERROR: '[ARTIST] Verify Email Phone Error',

  UPDATE_PASSWORD: '[ARTIST] Update Password',
  UPDATE_PASSWORD_SUCCESS: '[ARTIST] Update Password Success',
  UPDATE_PASSWORD_ERROR: '[ARTIST] Update Password Error',

  DELETE_PROFILE: '[ARTIST] Delete Profile',
  DELETE_PROFILE_SUCCESS: '[ARTIST] Delete Profile Success',
  DELETE_PROFILE_ERROR: '[ARTIST] Delete Profile Error',

  GET_ALL_BANKDETAIL: '[ARTIST] Get All bank detail',
  GET_ALL_BANKDETAIL_SUCCESS: '[ARTIST] Get All bank detail Success',
  GET_ALL_BANKDETAIL_ERROR: '[ARTIST] Get All bank detail Error',

  ADD_UPDATE_BANKDETAIL: '[ARTIST] Add / Update Bank Detail',
  ADD_UPDATE_BANKDETAIL_SUCCESS: '[ARTIST] Add / Update Bank Detail Success',
  ADD_UPDATE_BANKDETAIL_ERROR: '[ARTIST] Add / Update Bank Detail Error',

  DELETE_BANKDETAIL: '[ARTIST] Delete bank detail ',
  DELETE_BANKDETAIL_SUCCESS: '[ARTIST] Delete bank detail success',
  DELETE_BANKDETAIL_ERROR: '[ARTIST] Delete bank detail error',

  RESET_ARTIST_STATE: '[ARTIST] Reset Artist State',

  ADD_UPDATE_FANCLUB: '[ARTIST] Add / Update ADD_UPDATE_FANCLUB',
  ADD_UPDATE_FANCLUB_SUCCESS: '[ARTIST] Add / Update ADD_UPDATE_FANCLUB Success',
  ADD_UPDATE_FANCLUB_NAME_SUCCESS: '[ARTIST] Add / Update ADD_UPDATE_FANCLUB_NAME Success',
  ADD_UPDATE_FANCLUB_ERROR: '[ARTIST] Add / Update ADD_UPDATE_FANCLUB Error',

  GET_EVENT_LIST: '[ARTIST]  GET_EVENT_LIST',
  GET_EVENT_LIST_SUCCESS: '[ARTIST]  GET_EVENT_LIST Success',
  GET_EVENT_LIST_ERROR: '[ARTIST]  GET_EVENT_LIST Error',

  ADD_EXCLUSIVE_CONTENT: '[ARTIST] Add / Update ADD_EXCLUSIVE_CONTENT',
  ADD_FANCLUB_NAME: '[ARTIST] Add / Update ADD_FANCLUB_NAME',
  ADD_EXCLUSIVE_CONTENT_SUCCESS:
    '[ARTIST] Add / Update ADD_EXCLUSIVE_CONTENT Success',
  ADD_EXCLUSIVE_CONTENT_ERROR:
    '[ARTIST] Add / Update ADD_EXCLUSIVE_CONTENT Error',

  ADD_UPDATE_DISCOUNTS: '[ARTIST] Add / Update ADD_UPDATE_DISCOUNTS',
  ADD_UPDATE_DISCOUNTS_SUCCESS:
    '[ARTIST] Add / Update ADD_UPDATE_DISCOUNTS Success',
  ADD_UPDATE_DISCOUNTS_ERROR:
    '[ARTIST] Add / Update ADD_UPDATE_DISCOUNTS Error',

  ADD_UPDATE_LIVESHOW: '[ARTIST] Add / Update ADD_UPDATE_ LIVESHOW',
  ADD_UPDATE_LIVESHOW_SUCCESS: '[ARTIST] Add / Update ADD_UPDATE_LIVESHOW Success',
  ADD_UPDATE_LIVESHOW_ERROR: '[ARTIST] Add / Update ADD_UPDATE_LIVESHOW Error',

  GET_SUBSCRIPTION_LIST: '[ARTIST]  GET_SUBSCRIPTION_LIST',
  GET_SUBSCRIPTION_LIST_SUCCESS: '[ARTIST]  GET_SUBSCRIPTION_LIST Success',
  GET_SUBSCRIPTION_LIST_ERROR: '[ARTIST]  GET_SUBSCRIPTION_LIST Error',

  UPDATE_PLAN: '[ARTIST]  UPDATE_PLAN',
  UPDATE_PLAN_SUCCESS: '[ARTIST]  UPDATE_PLAN Success',
  UPDATE_PLAN_ERROR: '[ARTIST]  UPDATE_PLAN Error',

  GET_EXCLUSIVE_CONTENT_LIST: '[ARTIST]  GET_EXCLUSIVE_CONTENT_LIST',
  GET_EXCLUSIVE_CONTENT_LIST_SUCCESS:
    '[ARTIST]  GET_EXCLUSIVE_CONTENT_LIST Success',
  GET_EXCLUSIVE_CONTENT_LIST_ERROR:
    '[ARTIST]  GET_EXCLUSIVE_CONTENT_LIST Error',

  GET_LIVE_SHOWS_LIST: '[ARTIST]  GET_LIVE_SHOWS_LIST',
  GET_LIVE_SHOWS_LIST_SUCCESS: '[ARTIST]  GET_LIVE_SHOWS_LIST Success',
  GET_LIVE_SHOWS_LIST_ERROR: '[ARTIST]  GET_LIVE_SHOWS_LIST Error',

  GET_DISCOUNTS_LIST: '[ARTIST]  GET_DISCOUNTS_LIST',
  GET_DISCOUNTS_LIST_SUCCESS: '[ARTIST]  GET_DISCOUNTS_LIST Success',
  GET_DISCOUNTS_LIST_ERROR: '[ARTIST]  GET_DISCOUNTS_LIST Error',

  GET_EXCLUSIVE_CONTENT_LIST_ARTIST:
    '[ARTIST]  GET_EXCLUSIVE_CONTENT_LIST_ARTIST',
  GET_EXCLUSIVE_CONTENT_LIST_ARTIST_SUCCESS:
    '[ARTIST]  GET_EXCLUSIVE_CONTENT_LIST_ARTIST Success',
  GET_EXCLUSIVE_CONTENT_LIST_ARTIST_ERROR:
    '[ARTIST]  GET_EXCLUSIVE_CONTENT_LIST_ARTIST Error',

  DELETE_CONTENT: '[ARTIST] Delete Content',
  DELETE_CONTENT_SUCCESS: '[ARTIST] Delete Content Success',
  DELETE_CONTENT_ERROR: '[ARTIST] Delete Content Error',

  ADD_UPDATE_MERCHANDISING: '[ARTIST] Add / Update ADD_UPDATE_MERCHANDISING',
  ADD_UPDATE_MERCHANDISING_SUCCESS:
    '[ARTIST] Add / Update ADD_UPDATE_MERCHANDISING Success',
  ADD_UPDATE_MERCHANDISING_ERROR:
    '[ARTIST] Add / Update ADD_UPDATE_MERCHANDISIND Error',

  GET_MERCHANDISING_URL: '[ARTIST]  GET_MERCHANDISING_URL',
  GET_MERCHANDISING_URL_SUCCESS: '[ARTIST]  GET_MERCHANDISING_URL Success',
  GET_MERCHANDISING_URL_ERROR: '[ARTIST]  GET_MERCHANDISING_URL Error',

  GET_BILLING_DETAILS: '[ARTIST]  GET_BILLING_DETAILS',
  GET_BILLING_DETAILS_SUCCESS: '[ARTIST]  GET_BILLING_DETAILS Success',
  GET_BILLING_DETAILS_ERROR: '[ARTIST]  GET_BILLING_DETAILS Error',

  GET_PAYMENT_METHODS: '[ARTIST]  GET_PAYMENT_METHODS',
  GET_PAYMENT_METHODS_SUCCESS: '[ARTIST]  GET_PAYMENT_METHODS Success',
  GET_PAYMENT_METHODS_ERROR: '[ARTIST]  GET_PAYMENT_METHODS Error',

  GENERATE_RTM_TOKEN: '[ARTIST] Generate Rtm Token',
  GENERATE_RTM_TOKEN_SUCCESS: '[ARTIST] Generate Rtm Token Success',
  GENERATE_RTM_TOKEN_ERROR: '[ARTIST] Generate Rtm Token Error',

  DELETE_MERCHANDISE: '[ARTIST] Delete Merchandise',
  DELETE_MERCHANDISE_SUCCESS: '[ARTIST] Delete Merchandise Success',
  DELETE_MERCHANDISE_ERROR: '[ARTIST] Delete Merchandise Error',

  GET_ALL_RADIO_STATION: '[ARTIST] Get all Radio Stations',
  GET_ALL_RADIO_STATION_SUCCESS: '[ARTIST] Get all Radio Stations Success',
  GET_ALL_RADIO_STATION_ERROR: '[ARTIST] Get all Radio Stations Error',

  GET_RADIO_STATION_BY_ID: '[ARTIST] Get Radio station by id',
  GET_RADIO_STATION_BY_ID_SUCCESS: '[ARTIST] Get Radio station by id Success',
  GET_RADIO_STATION_BY_ID_ERROR: '[ARTIST] Get Radio station by id Error',

  ADD_UPDATE_RADIO_STATION: '[ARTIST] Add / Update Radio Station',
  ADD_UPDATE_RADIO_STATION_SUCCESS: '[ARTIST] Add / Update Radio Station Success',
  ADD_UPDATE_RADIO_STATION_ERROR: '[ARTIST] Add / Update Radio Station Error',

  DELETE_RADIO_STATION: '[ARTIST] Delete Radio Station',
  DELETE_RADIO_STATION_SUCCESS: '[ARTIST] Delete Radio Station Success',
  DELETE_RADIO_STATION_ERROR: '[ARTIST] Delete Radio Station Error',
};

export const GetBillingDetails = createAction(
  ArtistActions.GET_BILLING_DETAILS,
  (params: any = {}) => params
);
export const GetBillingDetailsSuccess = createAction(
  ArtistActions.GET_BILLING_DETAILS_SUCCESS,
  props<{ billingDetails: BillingDetails }>()
);
export const GetBillingDetailsError = createAction(
  ArtistActions.GET_BILLING_DETAILS_ERROR,
  props<{ error: string }>()
);

export const GetMerchandisingUrl = createAction(
  ArtistActions.GET_MERCHANDISING_URL,
  (params: any = {}) => params
);
export const GetMerchandisingUrlSuccess = createAction(
  ArtistActions.GET_MERCHANDISING_URL_SUCCESS,
  props<{ urlslist: MERCAHANDISING }>()
);
export const GetMerchandisingUrlError = createAction(
  ArtistActions.GET_MERCHANDISING_URL_ERROR,
  props<{ error: string }>()
);

export const AddUpdateMerchandising = createAction(
  ArtistActions.ADD_UPDATE_MERCHANDISING,
  props<{ body: any; id: string }>()
);
export const AddUpdateMerchandisingSuccess = createAction(
  ArtistActions.ADD_UPDATE_MERCHANDISING_SUCCESS,
  props<{ success: '' }>()
);
export const AddUpdateMerchandisingError = createAction(
  ArtistActions.ADD_UPDATE_MERCHANDISING_ERROR,
  props<{ error: string }>()
);

export const DeleteContent = createAction(
  ArtistActions.DELETE_CONTENT,
  props<{ content_id: string; data_id: string; fileType: string }>()
);
export const DeleteContentSuccess = createAction(
  ArtistActions.DELETE_CONTENT_SUCCESS
);
export const DeleteContentError = createAction(
  ArtistActions.DELETE_CONTENT_ERROR,
  props<{ error: string }>()
);

export const GetSubscriptionPlansListArtis = createAction(
  ArtistActions.GET_EXCLUSIVE_CONTENT_LIST_ARTIST,
  (params: any = {}) => params
);
export const GetSubscriptionPlansListArtisSuccess = createAction(
  ArtistActions.GET_EXCLUSIVE_CONTENT_LIST_ARTIST_SUCCESS,
  props<{ artistplans: SubscriptionPlan }>()
);
export const GetSubscriptionPlansListArtisError = createAction(
  ArtistActions.GET_EXCLUSIVE_CONTENT_LIST_ARTIST_ERROR,
  props<{ error: string }>()
);

export const GetDiscountList = createAction(
  ArtistActions.GET_DISCOUNTS_LIST,
  (params: any = {}) => params
);
export const GetDiscountListSuccess = createAction(
  ArtistActions.GET_DISCOUNTS_LIST_SUCCESS,
  props<{ discountlist: DiscountList }>()
);
export const GetDiscountListError = createAction(
  ArtistActions.GET_DISCOUNTS_LIST_ERROR,
  props<{ error: string }>()
);

export const GetLiveShowsList = createAction(
  ArtistActions.GET_LIVE_SHOWS_LIST,
  (params: any = {}) => params
);
export const GetLiveShowsListSuccess = createAction(
  ArtistActions.GET_LIVE_SHOWS_LIST_SUCCESS,
  props<{ showslist: ShowsList }>()
);
export const GetLiveShowsListError = createAction(
  ArtistActions.GET_LIVE_SHOWS_LIST_ERROR,
  props<{ error: string }>()
);

export const GetExclusiveContentList = createAction(
  ArtistActions.GET_EXCLUSIVE_CONTENT_LIST,
  (params: any = {}) => params
);
export const GetExclusiveContentListSuccess = createAction(
  ArtistActions.GET_EXCLUSIVE_CONTENT_LIST_SUCCESS,
  props<{ contentlist: ExclusiveContentList }>()
);
export const GetExclusiveContentListError = createAction(
  ArtistActions.GET_EXCLUSIVE_CONTENT_LIST_ERROR,
  props<{ error: string }>()
);

export const UpdatePlan = createAction(
  ArtistActions.UPDATE_PLAN,
  props<{ body: any }>()
);
export const UpdatePlanSuccess = createAction(
  ArtistActions.UPDATE_PLAN_SUCCESS,
  props<{ success: '' }>()
);
export const UpdatePlanError = createAction(
  ArtistActions.UPDATE_PLAN_ERROR,
  props<{ error: string }>()
);

export const GetSubscriptionPlansList = createAction(
  ArtistActions.GET_SUBSCRIPTION_LIST,
  (params: any = {}) => params
);
export const GetSubscriptionPlansListSuccess = createAction(
  ArtistActions.GET_SUBSCRIPTION_LIST_SUCCESS,
  props<{ plans: SubscriptionPlan }>()
);
export const GetSubscriptionPlansListError = createAction(
  ArtistActions.GET_SUBSCRIPTION_LIST_ERROR,
  props<{ error: string }>()
);

export const GetEventList = createAction(
  ArtistActions.GET_EVENT_LIST,
  (params: any = {}) => params
);
export const GetEventListSuccess = createAction(
  ArtistActions.GET_EVENT_LIST_SUCCESS,
  props<{ events: EventResponse }>()
);
export const GetEventListError = createAction(
  ArtistActions.GET_EVENT_LIST_ERROR,
  props<{ error: string }>()
);

export const AddUpdateLiveShow = createAction(
  ArtistActions.ADD_UPDATE_LIVESHOW,
  props<{ body: any }>()
);
export const AddUpdateLiveShowSuccess = createAction(
  ArtistActions.ADD_UPDATE_LIVESHOW_SUCCESS,
  props<{ liveShows: LiveShow }>()
);
export const AddUpdateLiveShowError = createAction(
  ArtistActions.ADD_UPDATE_LIVESHOW_ERROR,
  props<{ error: string }>()
);

export const AddUpdateDiscounts = createAction(
  ArtistActions.ADD_UPDATE_DISCOUNTS,
  props<{ body: any }>()
);
export const AddUpdateDiscountsSuccess = createAction(
  ArtistActions.ADD_UPDATE_DISCOUNTS_SUCCESS,
  props<{ discounts: Discount }>()
);
export const AddUpdateDiscountsError = createAction(
  ArtistActions.ADD_UPDATE_DISCOUNTS_ERROR,
  props<{ error: string }>()
);

export const AddExclusiveContent = createAction(
  ArtistActions.ADD_EXCLUSIVE_CONTENT,
  props<{
    body: any;
    content_id?: string;
    data_id?: string;
    dataType?: string;
  }>()
);
export const AddFanclubName = createAction(
  ArtistActions.ADD_FANCLUB_NAME,
  props<{
    body: any;
  }>()
);
export const AddExclusiveContentSuccess = createAction(
  ArtistActions.ADD_EXCLUSIVE_CONTENT_SUCCESS,
  props<{ exclusiveContent: ExclusiveContent }>()
);
export const AddExclusiveContentError = createAction(
  ArtistActions.ADD_EXCLUSIVE_CONTENT_ERROR,
  props<{ error: string }>()
);

export const GetArtistDashboard = createAction(
  ArtistActions.GET_ARTIST_DASHBOARD,
  (params: any = {}) => params
);
export const GetArtistDashboardSuccess = createAction(
  ArtistActions.GET_ARTIST_DASHBOARD_SUCCESS,
  props<{ dashboard: any }>()
);
export const GetArtistDashboardError = createAction(
  ArtistActions.GET_ARTIST_DASHBOARD_ERROR,
  props<{ error: string }>()
);

export const GetArtistProfile = createAction(
  ArtistActions.GET_ARTIST_PROFILE,
  props<{ id: string; query?: any }>()
);
export const GetArtistProfileSuccess = createAction(
  ArtistActions.GET_ARTIST_PROFILE_SUCCESS,
  props<{ artist: User }>()
);
export const GetArtistProfileError = createAction(
  ArtistActions.GET_ARTIST_PROFILE_ERROR,
  props<{ error: string }>()
);

export const AddFanClub = createAction(
  ArtistActions.ADD_UPDATE_FANCLUB,
  props<{ body: any }>()
);
export const AddFanClubSuccess = createAction(
  ArtistActions.ADD_UPDATE_FANCLUB_SUCCESS,
  props<{ fanClub: FanClub }>()
);
export const AddFanClubNameSuccess = createAction(
  ArtistActions.ADD_UPDATE_FANCLUB_NAME_SUCCESS,
  props<{ fanClub: FanClub }>()
);
export const AddFanClubError = createAction(
  ArtistActions.ADD_UPDATE_FANCLUB_ERROR,
  props<{ error: string }>()
);

export const StudioSetup = createAction(
  ArtistActions.SETUP_STUDIO,
  props<{ body: any }>()
);
export const StudioSetupSuccess = createAction(
  ArtistActions.SETUP_STUDIO_SUCCESS,
  props<{ artist: User }>()
);
export const StudioSetupError = createAction(
  ArtistActions.SETUP_STUDIO_ERROR,
  props<{ error: string }>()
);

export const CheckNameAvailability = createAction(
  ArtistActions.CHECK_NAME_AVAILABILITY,
  props<{ name: string }>()
);
export const CheckNameAvailabilitySuccess = createAction(
  ArtistActions.CHECK_NAME_AVAILABILITY_SUCCESS,
  props<{ isNameAvailable: string }>()
);
export const CheckNameAvailabilityError = createAction(
  ArtistActions.CHECK_NAME_AVAILABILITY_ERROR,
  props<{ error: string; isNameAvailable: string }>()
);

export const GetPodcastList = createAction(
  ArtistActions.GET_PODCAST_LIST,
  (params: any = {}) => params
);
export const GetPodcastListSuccess = createAction(
  ArtistActions.GET_PODCAST_LIST_SUCCESS,
  props<{ podcasts: PaginatedPodcast }>()
);
export const GetPodcastListError = createAction(
  ArtistActions.GET_PODCAST_LIST_ERROR,
  props<{ error: string }>()
);

export const GetPodcastEpisodeList = createAction(
  ArtistActions.GET_PODCAST_EPISODE_LIST,
  props<{ podcast_id: string, isForWeb: any }>()
);
export const GetPodcastEpisodeListSuccess = createAction(
  ArtistActions.GET_PODCAST_EPISODE_LIST_SUCCESS,
  props<{ episodes: Episode[] }>()
);
export const GetPodcastEpisodeListError = createAction(
  ArtistActions.GET_PODCAST_EPISODE_LIST_ERROR,
  props<{ error: string }>()
);

export const DeletePodcast = createAction(
  ArtistActions.DELETE_PODCAST,
  props<{ podcast_id: string }>()
);
export const DeletePodcastSuccess = createAction(
  ArtistActions.DELETE_PODCAST_SUCCESS
);
export const DeletePodcastError = createAction(
  ArtistActions.DELETE_PODCAST_ERROR,
  props<{ error: string }>()
);

export const DeleteEpisode = createAction(
  ArtistActions.DELETE_EPISODE,
  props<{ episode_id: string }>()
);
export const DeleteEpisodeSuccess = createAction(
  ArtistActions.DELETE_EPISODE_SUCCESS
);
export const DeleteEpisodeError = createAction(
  ArtistActions.DELETE_EPISODE_ERROR,
  props<{ error: string }>()
);

export const GetAllCategory = createAction(
  ArtistActions.GET_ALL_CATEGORY,
  (params: any = {}) => params
);
export const GetAllCategorySuccess = createAction(
  ArtistActions.GET_ALL_CATEGORY_SUCCESS,
  props<{ categories: Category[] }>()
);
export const GetAllCategoryError = createAction(
  ArtistActions.GET_ALL_CATEGORY_ERROR,
  props<{ error: string }>()
);

export const AddUpdatePodcast = createAction(
  ArtistActions.ADD_UPDATE_PODCAST,
  props<{ body: any }>()
);
export const AddUpdatePodcastSuccess = createAction(
  ArtistActions.ADD_UPDATE_PODCAST_SUCCESS,
  props<{ podcast: Podcast }>()
);
export const AddUpdatePodcastError = createAction(
  ArtistActions.ADD_UPDATE_PODCAST_ERROR,
  props<{ error: string }>()
);

export const GetPodcastById = createAction(
  ArtistActions.GET_PODCAST_BY_ID,
  props<{ podcast_id: string; query?: any }>()
);
export const GetPodcastByIdSuccess = createAction(
  ArtistActions.GET_PODCAST_BY_ID_SUCCESS,
  props<{ podcast: Podcast }>()
);
export const GetPodcastByIdError = createAction(
  ArtistActions.GET_PODCAST_BY_ID_ERROR,
  props<{ error: string }>()
);

export const AddUpdateEpisode = createAction(
  ArtistActions.ADD_UPDATE_EPISODE,
  props<{ body: any }>()
);
export const AddUpdateEpisodeSuccess = createAction(
  ArtistActions.ADD_UPDATE_EPISODE_SUCCESS,
  props<{ podcast: Podcast }>()
);
export const AddUpdateEpisodeError = createAction(
  ArtistActions.ADD_UPDATE_EPISODE_ERROR,
  props<{ error: string }>()
);

export const GetEpisodeById = createAction(
  ArtistActions.GET_EPISODE_BY_ID,
  props<{ podcast_id: string; episode_id: string; query?: any }>()
);
export const GetEpisodeByIdSuccess = createAction(
  ArtistActions.GET_EPISODE_BY_ID_SUCCESS,
  props<{ episode: Episode }>()
);
export const GetEpisodeByIdError = createAction(
  ArtistActions.GET_EPISODE_BY_ID_ERROR,
  props<{ error: string }>()
);

export const AddUpdateAllEpisode = createAction(
  ArtistActions.ADD_UPDATE_ALL_EPISODE,
  props<{ body: any[] }>()
);
export const AddUpdateAllEpisodeSuccess = createAction(
  ArtistActions.ADD_UPDATE_ALL_EPISODE_SUCCESS,
  props<{ podcast: Podcast }>()
);
export const AddUpdateAllEpisodeError = createAction(
  ArtistActions.ADD_UPDATE_ALL_EPISODE_ERROR,
  props<{ error: string }>()
);

export const UpdatePodcastTerms = createAction(
  ArtistActions.UPDATE_PODCAST_TERMS,
  props<{ body: string[] }>()
);
export const UpdatePodcastTermsSuccess = createAction(
  ArtistActions.UPDATE_PODCAST_TERMS_SUCCESS
);
export const UpdatePodcastTermsError = createAction(
  ArtistActions.UPDATE_PODCAST_TERMS_ERROR,
  props<{ error: string }>()
);

export const GetSkitsList = createAction(
  ArtistActions.GET_SKITS_LIST,
  (params: any = {}) => params
);
export const GetSkitsListSuccess = createAction(
  ArtistActions.GET_SKITS_LIST_SUCCESS,
  props<{ skits: PaginatedSkits }>()
);
export const GetSkitsListError = createAction(
  ArtistActions.GET_SKITS_LIST_ERROR,
  props<{ error: string }>()
);

export const DeleteSkit = createAction(
  ArtistActions.DELETE_SKIT,
  props<{ skit_id: string }>()
);
export const DeleteSkitSuccess = createAction(
  ArtistActions.DELETE_SKIT_SUCCESS
);
export const DeleteSkitError = createAction(
  ArtistActions.DELETE_SKIT_ERROR,
  props<{ error: string }>()
);

export const AddUpdateSkit = createAction(
  ArtistActions.ADD_UPDATE_SKIT,
  props<{ body: any }>()
);
export const AddUpdateSkitSuccess = createAction(
  ArtistActions.ADD_UPDATE_SKIT_SUCCESS,
  props<{ skit: Skits }>()
);
export const AddUpdateSkitError = createAction(
  ArtistActions.ADD_UPDATE_SKIT_ERROR,
  props<{ error: string }>()
);

export const GetSkitById = createAction(
  ArtistActions.GET_SKIT_BY_ID,
  props<{ skit_id: string; query?: any }>()
);
export const GetSkitByIdSuccess = createAction(
  ArtistActions.GET_SKIT_BY_ID_SUCCESS,
  props<{ skit: Skits }>()
);
export const GetSkitByIdError = createAction(
  ArtistActions.GET_SKIT_BY_ID_ERROR,
  props<{ error: string }>()
);

export const UpdateSkitsTerms = createAction(
  ArtistActions.UPDATE_SKITS_TERMS,
  props<{ body: string[] }>()
);
export const UpdateSkitsTermsSuccess = createAction(
  ArtistActions.UPDATE_SKITS_TERMS_SUCCESS
);
export const UpdateSkitsTermsError = createAction(
  ArtistActions.UPDATE_SKITS_TERMS_ERROR,
  props<{ error: string }>()
);

export const UpdateArtist = createAction(
  ArtistActions.UPDATE_ARTIST,
  props<{ id: string; body: any }>()
);
export const UpdateArtistSuccess = createAction(
  ArtistActions.UPDATE_ARTIST_SUCCESS,
  props<{ artist: User }>()
);
export const UpdateArtistError = createAction(
  ArtistActions.UPDATE_ARTIST_ERROR,
  props<{ error: string }>()
);

export const VerifyEmailPhone = createAction(
  ArtistActions.VERIFY_EMAIL_PHONE,
  props<{ body: any }>()
);
export const VerifyEmailPhoneSuccess = createAction(
  ArtistActions.VERIFY_EMAIL_PHONE_SUCCESS
);
export const VerifyEmailPhoneError = createAction(
  ArtistActions.VERIFY_EMAIL_PHONE_ERROR,
  props<{ error: string }>()
);

export const UpdatePassword = createAction(
  ArtistActions.UPDATE_PASSWORD,
  props<{ body: any }>()
);
export const UpdatePasswordSuccess = createAction(
  ArtistActions.UPDATE_PASSWORD_SUCCESS
);
export const UpdatePasswordError = createAction(
  ArtistActions.UPDATE_PASSWORD_ERROR,
  props<{ error: string }>()
);

export const DeleteProfile = createAction(
  ArtistActions.DELETE_PROFILE,
  props<{ id: string }>()
);
export const DeleteProfileSuccess = createAction(
  ArtistActions.DELETE_PROFILE_SUCCESS
);
export const DeleteProfileError = createAction(
  ArtistActions.DELETE_PROFILE_ERROR,
  props<{ error: string }>()
);

export const GetMusicList = createAction(
  ArtistActions.GET_MUSIC_LIST,
  (params: any = {}) => params
);
export const GetMusicListSuccess = createAction(
  ArtistActions.GET_MUSIC_LIST_SUCCESS,
  props<{ musics: PaginatedMusic }>()
);
export const GetMusicListError = createAction(
  ArtistActions.GET_MUSIC_LIST_ERROR,
  props<{ error: string }>()
);

export const GetMusicTrackList = createAction(
  ArtistActions.GET_MUSIC_TRACK_LIST,
  props<{ music_id: string; query?: any }>()
);
export const GetMusicTrackListSuccess = createAction(
  ArtistActions.GET_MUSIC_TRACK_LIST_SUCCESS,
  props<{ tracks: Tracks[] }>()
);
export const GetMusicTrackListError = createAction(
  ArtistActions.GET_MUSIC_TRACK_LIST_ERROR,
  props<{ error: string }>()
);

export const DeleteMusic = createAction(
  ArtistActions.DELETE_MUSIC,
  props<{ music_id: string }>()
);
export const DeleteMusicSuccess = createAction(
  ArtistActions.DELETE_MUSIC_SUCCESS
);
export const DeleteMusicError = createAction(
  ArtistActions.DELETE_MUSIC_ERROR,
  props<{ error: string }>()
);

export const DeleteTrack = createAction(
  ArtistActions.DELETE_TRACK,
  props<{ track_id: string }>()
);
export const DeleteTrackSuccess = createAction(
  ArtistActions.DELETE_TRACK_SUCCESS
);
export const DeleteTrackError = createAction(
  ArtistActions.DELETE_TRACK_ERROR,
  props<{ error: string }>()
);

export const GetAllGenres = createAction(
  ArtistActions.GET_ALL_GENRE,
  (params: any = {}) => params
);
export const GetAllGenresSuccess = createAction(
  ArtistActions.GET_ALL_GENRE_SUCCESS,
  props<{ genres: Category[] }>()
);
export const GetAllGenresError = createAction(
  ArtistActions.GET_ALL_GENRE_ERROR,
  props<{ error: string }>()
);

export const AddUpdateMusic = createAction(
  ArtistActions.ADD_UPDATE_MUSIC,
  props<{ body: any }>()
);
export const AddUpdateMusicSuccess = createAction(
  ArtistActions.ADD_UPDATE_MUSIC_SUCCESS,
  props<{ music: Music }>()
);
export const AddUpdateMusicError = createAction(
  ArtistActions.ADD_UPDATE_MUSIC_ERROR,
  props<{ error: string }>()
);

export const GetMusicById = createAction(
  ArtistActions.GET_MUSIC_BY_ID,
  props<{ music_id: string; query?: any }>()
);
export const GetMusicByIdSuccess = createAction(
  ArtistActions.GET_MUSIC_BY_ID_SUCCESS,
  props<{ music: Music }>()
);
export const GetMusicByIdError = createAction(
  ArtistActions.GET_MUSIC_BY_ID_ERROR,
  props<{ error: string }>()
);

export const AddUpdateTrack = createAction(
  ArtistActions.ADD_UPDATE_TRACK,
  props<{ body: any }>()
);
export const AddUpdateTrackSuccess = createAction(
  ArtistActions.ADD_UPDATE_TRACK_SUCCESS,
  props<{ music: Music }>()
);
export const AddUpdateTrackError = createAction(
  ArtistActions.ADD_UPDATE_TRACK_ERROR,
  props<{ error: string }>()
);

export const GetTrackById = createAction(
  ArtistActions.GET_TRACK_BY_ID,
  props<{ music_id: string; track_id: string; query?: any }>()
);
export const GetTrackByIdSuccess = createAction(
  ArtistActions.GET_TRACK_BY_ID_SUCCESS,
  props<{ track: Tracks }>()
);
export const GetTrackByIdError = createAction(
  ArtistActions.GET_TRACK_BY_ID_ERROR,
  props<{ error: string }>()
);

export const AddUpdateAllTracks = createAction(
  ArtistActions.ADD_UPDATE_ALL_TRACK,
  props<{ body: any[] }>()
);
export const AddUpdateAllTracksSuccess = createAction(
  ArtistActions.ADD_UPDATE_ALL_TRACK_SUCCESS,
  props<{ music: Music }>()
);
export const AddUpdateAllTracksError = createAction(
  ArtistActions.ADD_UPDATE_ALL_TRACK_ERROR,
  props<{ error: string }>()
);

export const UpdateMusicTerms = createAction(
  ArtistActions.UPDATE_MUSIC_TERMS,
  props<{ body: string[] }>()
);
export const UpdateMusicTermsSuccess = createAction(
  ArtistActions.UPDATE_MUSIC_TERMS_SUCCESS
);
export const UpdateMusicTermsError = createAction(
  ArtistActions.UPDATE_MUSIC_TERMS_ERROR,
  props<{ error: string }>()
);

export const GetAllBankDetail = createAction(
  ArtistActions.GET_ALL_BANKDETAIL,
  (items: any = {}) => items
);
export const GetAllBankDetailSuccess = createAction(
  ArtistActions.GET_ALL_BANKDETAIL_SUCCESS,
  props<{ bankDetails: BankDetail[] }>()
);
export const GetAllBankDetailError = createAction(
  ArtistActions.GET_ALL_BANKDETAIL_ERROR,
  props<{ error: string }>()
);

export const AddUpdateBankDetail = createAction(
  ArtistActions.ADD_UPDATE_BANKDETAIL,
  props<{ body: any }>()
);
export const AddUpdateBankDetailSuccess = createAction(
  ArtistActions.ADD_UPDATE_BANKDETAIL_ERROR,
  props<{ bankDetail: BankDetail }>()
);
export const AddUpdateBankDetailError = createAction(
  ArtistActions.ADD_UPDATE_BANKDETAIL_ERROR,
  props<{ error: string }>()
);

export const DeleteBankDetail = createAction(
  ArtistActions.DELETE_BANKDETAIL,
  props<{ bankDetailId: any }>()
);
export const DeleteBankDetailSuccess = createAction(
  ArtistActions.DELETE_BANKDETAIL_SUCCESS
);
export const DeleteBankDetailError = createAction(
  ArtistActions.DELETE_BANKDETAIL_ERROR,
  props<{ error: string }>()
);

export const GenerateRtmToken = createAction(
  ArtistActions.GENERATE_RTM_TOKEN,
  (params: any = {}) => params );
export const GenerateRtmTokenSuccess = createAction(
  ArtistActions.GENERATE_RTM_TOKEN_SUCCESS,
  props<{ rtmToken: any }>()
);
export const GenerateRtmTokenError = createAction(
  ArtistActions.GENERATE_RTM_TOKEN_ERROR,
  props<{ error: string }>()
);

export const DeleteMerchandise = createAction(
  ArtistActions.DELETE_MERCHANDISE,
  props<{ merchandise_id: string }>()
);
export const DeleteMerchandiseSuccess = createAction(
  ArtistActions.DELETE_MERCHANDISE_SUCCESS
);
export const DeleteMerchandiseError = createAction(
  ArtistActions.DELETE_MERCHANDISE_ERROR,
  props<{ error: string }>()
);

export const ResetArtistState = createAction(
  ArtistActions.RESET_ARTIST_STATE,
  (params: any = {}) => params
);

export const GetAllRadioStations = createAction(ArtistActions.GET_ALL_RADIO_STATION, (params: any = {}) => params);
export const GetAllRadioStationsSuccess = createAction(ArtistActions.GET_ALL_RADIO_STATION_SUCCESS, props<{ radio_stations: any }>());
export const GetAllRadioStationsError = createAction(ArtistActions.GET_ALL_RADIO_STATION_ERROR, props<{ error: string }>());

export const GetRadioStationById = createAction(ArtistActions.GET_RADIO_STATION_BY_ID, props<{ radio_station_id: string, query?: any }>());
export const GetRadioStationByIdSuccess = createAction(ArtistActions.GET_RADIO_STATION_BY_ID_SUCCESS, props<{ radio_station: any }>());
export const GetRadioStationByIdError = createAction(ArtistActions.GET_RADIO_STATION_BY_ID_ERROR, props<{ error: string }>());

export const AddUpdateRadioStation = createAction(ArtistActions.ADD_UPDATE_RADIO_STATION, props<{ body: any }>());
export const AddUpdateRadioStationSuccess = createAction(ArtistActions.ADD_UPDATE_RADIO_STATION_SUCCESS, props<{ radio_station: any }>());
export const AddUpdateRadioStationError = createAction(ArtistActions.ADD_UPDATE_RADIO_STATION_ERROR, props<{ error: string }>());

export const DeleteRadioStation = createAction(ArtistActions.DELETE_RADIO_STATION, props<{ id: string }>());
export const DeleteRadioStationSuccess = createAction(ArtistActions.DELETE_RADIO_STATION_SUCCESS, props<{ success: any }>());
export const DeleteRadioStationError = createAction(ArtistActions.DELETE_RADIO_STATION_ERROR, props<{ error: string }>());
