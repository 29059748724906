import {Component, ViewEncapsulation} from '@angular/core';

@Component({
  selector: 'frontend-verify-profile',
  templateUrl: './verify-profile.component.html',
  styleUrls: ['./verify-profile.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class VerifyProfileComponent {
  selectedValue = 1;
  isMultiple = false;
  selectOptions = [
    {id: 1, name: 'Last Week'},
    {id: 2, name: 'Last Month'},
    {id: 3, name: 'Last year'},
  ];
}
