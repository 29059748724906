import {Component, Input} from '@angular/core';
import {NavigationEnd, Router} from "@angular/router";
import {select, Store} from "@ngrx/store";
import {AuthState, getLoggedInUser} from "@frontend/auth-store";
import {Subject, takeUntil} from "rxjs";
import {ArtistState, getArtist, GetArtistProfile} from "@frontend/artist-store";
import {User} from "@frontend/data-models";
// import {User} from "@frontend/data-models";

@Component({
  selector: 'frontend-bottom-header',
  templateUrl: './bottom-header.component.html',
  styleUrls: ['./bottom-header.component.scss'],
})
export class BottomHeaderComponent {
  currentRoute: string;
  @Input() currentUser: any;
  artist: User;
  unsubscriber = new Subject();

  constructor(
    private router: Router,
    private artistStore: Store<ArtistState>,
    private authStore: Store<AuthState>
  ) {
    this.router.events.subscribe((event: any) => {
      if (event instanceof NavigationEnd) {
        const url = event.url.split('/').filter(t => t);
        if (url[0]) {
          this.currentRoute = url[0].indexOf('?') !== -1 ? url[0].substr(0, url[0].indexOf('?')): url[0];
        } else {
          this.currentRoute = 'dashboard';
        }
      }
    })
  }

  ngOnInit(): void {
    if (!this.artist) {
      this.subscribeToStore();
    }
  }
  subscribeToStore() {
    this.authStore
      .pipe(select(getLoggedInUser))
      .pipe(takeUntil(this.unsubscriber))
      .subscribe((user) => {
        if (user) {
          this.artistStore.dispatch(
            GetArtistProfile({ id: user._id, query: { allFields: true } })
          );
        }
      });
    this.artistStore
      .pipe(select(getArtist))
      .pipe(takeUntil(this.unsubscriber))
      .subscribe((artist) => {
        if (artist) {
          this.artist = artist;
        }
      });
  }
}
