import {EventManager} from '@angular/platform-browser';
import {EventEmitter, Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class WindowService {

  public onResize$ = new EventEmitter<{ width: number; height: number; }>();

  constructor(eventManager: EventManager) {
    eventManager.addGlobalEventListener('window', 'resize',
      (e: { target: { innerWidth: any; innerHeight: any; }; }) => this.onResize$.emit({
        width: e.target.innerWidth,
        height: e.target.innerHeight
      }));
  }

  getScreenSize() {
    return window.innerWidth;
  }

}
