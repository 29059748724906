import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'frontend-live-show-expended',
  templateUrl: './live-show-expended.component.html',
  styleUrls: ['./live-show-expended.component.scss'],
})
export class LiveShowExpendedComponent implements OnInit {
  visible: boolean = true;
  constructor() {}

  ngOnInit(): void {}
  onClick() {
    this.visible = !this.visible;
  }
}
