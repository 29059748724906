export const TargetAudience = [
  {name: 'For all audience', id: 'all'},
  {name: 'Above 16 year old', id: 'above 16'},
  {name: 'Above 18 year old', id: 'above 18'},
];

export const Language = [
  {name: 'English', id: 'english'},
  {name: 'Hindi', id: 'hindi'},
  {name: 'German', id: 'german'},
  {name: 'Arabic', id: 'arabic'},
];
