import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

export {ArtistType, ArtistRole, ArtistDocument, RecordDocument, StudioStatus} from "./consts/Artists"
export {SocialMedias} from "./consts/SocialMedias"
export {TargetAudience, Language} from "./consts/TargetAudience"
export {currenciesWithSymbol} from './consts/AllowedCurrency';

export {User, PaginatedUser} from "./models/User"
export {SocialUser} from './models/SocialUser'
export {Artist} from "./models/Artist"
export {FanClub} from "./models/fanClubDetails"
export {Category} from "./models/Category"
export {Country} from "./models/Country"
export {Episode} from "./models/Episode"
export {Genres} from "./models/Genres"
export {Podcast, PaginatedPodcast} from "./models/Podcast"
export {Skits, PaginatedSkits} from "./models/Skits"
export {Comment} from "./models/Comment"
export {Music, PaginatedMusic} from "./models/Music"
export {Tracks} from "./models/Tracks"
export {BankDetail} from  "./models/Bankdetail"
export {Ads, AdsAudience, PaginatedAds} from  "./models/Ads"
export {Event, EventResponse} from "./models/Event"
export {ExclusiveContent} from "./models/ExclusiveContent"
export {Discount,DiscountList} from "./models/Discounts"
export {LiveShow} from "./models/Liveshow"
export {ShowsList} from "./models/Liveshow"
export {CurrencyData} from './models/CurrencyData';
export {PromoCode, PaginatedPromoCode} from './models/PromoCode';

export {SubscriptionPlan} from "./models/SubscriptionPlan"
export {Plan} from "./models/Plans"
export {PlanList} from "./models/Plans"
export {ExclusiveContentList} from "./models/ExclusiveContent"
export {MERCAHANDISING} from "./models/MerchandingUrl"
export {BillingDetails} from "./models/BillingDetails"

@NgModule({
  imports: [CommonModule],
})
export class DataModelsModule {
}
