import {Injectable} from '@angular/core';
import {CanActivate, Router} from '@angular/router';
import {map} from 'rxjs';
import {AuthState, getLoggedInUser} from "@frontend/auth-store";
import {select, Store} from "@ngrx/store";

@Injectable({
  providedIn: 'root'
})
export class NoAuthGuard implements CanActivate {

  isVerifyEmailRoute = false;

  constructor(
    private router: Router,
    private authStore: Store<AuthState>
  ) {
    const url = window.location.href;
    if (url.includes('verify-email') || url.includes('email-verification')) {
      this.isVerifyEmailRoute = true;
    }
  }

  canActivate() {
    return this.authStore.pipe(select(getLoggedInUser)).pipe(
      map(currentUser => {
        // const commonData = this.localStorageService.updateCommonKey('get');
        // let allowRedirect = true;
        // if (commonData) {
        //   allowRedirect = commonData.redirect;
        // }
        // if (currentUser && !this.isVerifyEmailRoute && allowRedirect) {
        if (currentUser) {
          if (Number(currentUser['stdio_status']) === 3) {
            this.router.navigate(['/dashboard']);
          } else {
            this.router.navigate(['/setup']);
          }
          return false;
        }
        return true;
      })
    )
  }

}
