export const ArtistType = [
  {
    name: 'Musicians',
    image: 'assets/images/Product.png',
  },
  {
    name: 'Video',
    image: 'assets/images/Product1.png',
  },
  {
    name: 'Podcasters',
    image: 'assets/images/Product2.png',
  },
  {
    name: 'Radio',
    image: 'assets/images/card-img.png',
  },
];

export const ArtistRole = [
  { id: 'record_label', name: 'Record label' },
  { id: 'artist', name: 'Artist' },
];

export const ArtistDocument = [
  { id: 'passport', name: 'International passport' },
  { id: 'national_id', name: 'NID (Nigerian National ID Card)' },
  { id: 'license', name: 'Driver license' },
];

export const RecordDocument = [
  { id: 'crc', name: 'Company Registration Certificate' },
];

export const StudioStatus: any = {
  0: 'Category',
  1: 'Category',
  2: 'Profile',
  3: 'Document',
};
