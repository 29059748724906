<div class="body_bg">
  <div class="main_wrapper">
    <frontend-verify-header></frontend-verify-header>
    <frontend-side-bar></frontend-side-bar>
    <div class="main_content">
      <div class="profile_verification_main">
        <div class="row">
          <div class="col-lg-4">
            <div class="complete_profile">
              <p>
                Complete your <br />
                artist’s profile
              </p>
              <div class="progress_bars">
                <div class="progres"><img src="assets/images/Bar.png" /></div>
                <div class="progress_percent">80%</div>
              </div>
              <div class="artist_lists">
                <ul>
                  <li>
                    <h4>Upload songs & albums</h4>
                    <span> <img src="assets/images/upload.svg" /></span>
                  </li>
                  <li>
                    <h4>Create playlists</h4>
                    <span> <img src="assets/images/plus.svg" /></span>
                  </li>
                  <li>
                    <h4>Tax Identity Information</h4>
                    <span> <img src="assets/images/upload.svg" /></span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="col-lg-4">
            <div class="promote">
              <div class="date">
                <h1>24</h1>
                <p>JUNE</p>
              </div>

              <div class="live_shows">
                <span><img src="assets/images/signal.png" /></span>
                <h1>Live show</h1>
              </div>

              <div class="timers">
                <div class="day_event">
                  <div class="counter">
                    <span>0</span>
                    <span>2</span>
                  </div>
                  <p>DAYS</p>
                </div>
                <span class="dots">:</span>
                <div class="day_event">
                  <div class="counter">
                    <span>1</span>
                    <span>2</span>
                  </div>
                  <p>HOURS</p>
                </div>
                <span class="dots">:</span>
                <div class="day_event">
                  <div class="counter">
                    <span>4</span>
                    <span>2</span>
                  </div>
                  <p>MINUTES</p>
                </div>
              </div>

              <div class="start_soon">
                Your show will star soon. Do you have all you need?
              </div>
            </div>
          </div>
          <div class="col-lg-4">
            <div class="profile_not_verified">
              <h4 class="">
                Complete your bank <br />
                details
              </h4>
              <p class="profile_para_text">
                Add your bank account to which you wish to receive the deposits
              </p>
              <a href="#">Add my bank information</a>
            </div>
          </div>
        </div>
      </div>
      <div class="your_stats">
        <div class="stats_heading_bar">
          <div class="stats_heading">Your stats</div>
          <frontend-custom-select
            class="stats_btn"
            [(selectedValue)]="selectedValue"
            [selectOptions]="selectOptions"
            placeholder="Select your country "
            [searchable]="false"
          >
          </frontend-custom-select>
        </div>
        <div class="stats_count">
          <div class="row">
            <div class="col-lg-6">
              <div class="stats_graph">
                <div class="stats_box_title">
                  <img src="assets/images/Collaborative.svg" />
                  <h6 class="">Audience</h6>
                </div>
                <div class="stats_box_counting">
                  <h3 class="">0</h3>
                </div>
                <div class="stats_graph_box">
                  <img src="assets/images/Graph.png" />
                  <p class="graph_box_days">
                    <span>Mon</span> <span>Tue</span> <span>Wed</span>
                    <span>Thu</span> <span>Fri</span> <span>Sat</span>
                    <span>Sun</span>
                  </p>
                </div>
              </div>
            </div>
            <div class="col-lg-6">
              <div class="stats_count_boxes">
                <div class="row">
                  <div class="col-lg-4 col-sm-6">
                    <div class="stats_small_box">
                      <div class="stats_box_title">
                        <img src="assets/images/Streams.svg" />
                        <h6 class="">Streams</h6>
                      </div>
                      <div class="stats_box_counting">
                        <h3 class="">0</h3>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-4 col-sm-6">
                    <div class="stats_small_box">
                      <div class="stats_box_title">
                        <img src="assets/images/Friends.png" />
                        <h6 class="">Listeners</h6>
                      </div>
                      <div class="stats_box_counting">
                        <h3 class="">0</h3>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-4 col-sm-6">
                    <div class="stats_small_box">
                      <div class="stats_box_title">
                        <img src="assets/images/Followers.png" />
                        <h6 class="">Followers</h6>
                      </div>
                      <div class="stats_box_counting">
                        <h3 class="">0</h3>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-4 col-sm-6">
                    <div class="stats_small_box">
                      <div class="stats_box_title">
                        <img src="assets/images/Revenue.svg" />
                        <h6 class="">Revenue</h6>
                      </div>
                      <div class="stats_box_counting">
                        <h3 class="">0</h3>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-4 col-sm-6">
                    <div class="stats_small_box">
                      <div class="stats_box_title">
                        <img src="assets/images/View.svg" />
                        <h6 class="">Visits</h6>
                      </div>
                      <div class="stats_box_counting">
                        <h3 class="">0</h3>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-4 col-sm-6">
                    <div class="stats_small_box">
                      <div class="stats_box_title">
                        <img src="assets/images/Actions.svg" />
                        <h6 class="">Downloads</h6>
                      </div>
                      <div class="stats_box_counting">
                        <h3 class="">0</h3>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="top_list">
        <div class="row">
          <div class="col-lg-6">
            <div class="your_stats">
              <div class="stats_heading_bar1">
                <div class="stats_heading">Your top songs</div>
                <div class="reproductions">Reproductions</div>
              </div>
              <div class="top_songs">
                <ul>
                  <li>
                    <div class="artist_detials">
                      <div class="artist_img">
                        <img src="assets/images/artist_photo.png" />
                      </div>
                      <div class="artist_content">
                        <h1>Song’s name goes here</h1>
                        <span>Album title</span>
                      </div>
                    </div>
                    <div class="artist_count">
                      <p>83</p>
                    </div>
                  </li>

                  <li>
                    <div class="artist_detials">
                      <div class="artist_img">
                        <img src="assets/images/Photo2.png" />
                      </div>
                      <div class="artist_content">
                        <h1>Song’s name goes here</h1>
                        <span>Album title</span>
                      </div>
                    </div>
                    <div class="artist_count">
                      <p>83</p>
                    </div>
                  </li>

                  <li>
                    <div class="artist_detials">
                      <div class="artist_img">
                        <img src="assets/images/Photo3.png" />
                      </div>
                      <div class="artist_content">
                        <h1>Song’s name goes here</h1>
                        <span>Album title</span>
                      </div>
                    </div>
                    <div class="artist_count">
                      <p>83</p>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="your_stats">
              <div class="stats_heading_bar1">
                <div class="stats_heading">Your top playlists</div>
                <div class="reproductions">Reproductions</div>
              </div>

              <div class="top_songs">
                <ul>
                  <li>
                    <div class="artist_detials">
                      <div class="artist_img">
                        <img src="assets/images/playlist.png" />
                      </div>
                      <div class="artist_content">
                        <h1>Playlist name goes here</h1>
                        <span>220 mins · 48 songs</span>
                      </div>
                    </div>
                    <div class="artist_count">
                      <p>83</p>
                    </div>
                  </li>

                  <li>
                    <div class="artist_detials">
                      <div class="artist_img">
                        <img src="assets/images/playlists2.png" />
                      </div>
                      <div class="artist_content">
                        <h1>Playlist name goes here</h1>
                        <span>220 mins · 48 songs</span>
                      </div>
                    </div>
                    <div class="artist_count">
                      <p>83</p>
                    </div>
                  </li>

                  <li>
                    <div class="artist_detials">
                      <div class="artist_img">
                        <img src="assets/images/playlists3.png" />
                      </div>
                      <div class="artist_content">
                        <h1>Playlist name goes here</h1>
                        <span>220 mins · 48 songs</span>
                      </div>
                    </div>
                    <div class="artist_count">
                      <p>83</p>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="navbar_mob">
      <div class="bottom_bar">
        <span class="dashboard_btn_mob"
          ><img
            class="list_icon"
            src="assets/images/Dashboard_mob_navbar_icon.png"
        /></span>
        <ul class="aside_list">
          <li class="">
            <div class="lsit_item">
              <img
                class="list_icon"
                src="assets/images/mob_tab_music_icon.svg"
              />
              <p class="item_text">Music</p>
            </div>
          </li>
          <li class="">
            <div class="lsit_item">
              <img
                class="list_icon"
                src="assets/images/mob_tab_finances_icon.svg"
              />
              <p class="item_text">Finances</p>
            </div>
          </li>
          <li class="">
            <div class="lsit_item">
              <img
                class="list_icon"
                src="assets/images/mob_tab_Help_icon.svg"
              />
              <p class="item_text">Help</p>
            </div>
          </li>
          <li class="">
            <div class="lsit_item">
              <img
                class="list_icon"
                src="assets/images/mob_tab_profile_icon.svg"
              />
              <p class="item_text">Profile</p>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>
