<div class="body_bg ht100">
  <section class="top_header_section">
    <div class="container-fluid">
      <div class="row">
        <div class="col-12">
          <div class="top_setting_main">
            <div class="kwot_logo">
              <img src="assets/images/Logo.png" />
            </div>
            <div class="category_list">1. General detailst</div>
            <div class="category_list">2. Episodes</div>
            <div class="category_list active_category">3. Important checkboxes</div>

            <div class="category_cross">
              <img src="assets/images/Cross1.png" />
            </div>
          </div>
          <div class="border_btm"></div>
        </div>
      </div>
    </div>
  </section>

  <section class="steps_section_main">
    <div class="row">
      <div class="col-md-4 col-lg-3 pr_0">
        <div class="type_artist">
          <div class="artsit_heading">
            <h1>Important checkboxes <span>(mandatory)</span></h1>
            <p>Read the information carefully to upload your music.</p>
          </div>
        </div>
      </div>
      <div class="col-md-8 col-lg-9 pl_0">
        <div class="category_mains">
          <div class="imp_checkbox">
            <div class="heading_for_mobile">
              <h1>Important checkboxes <span>(mandatory)</span></h1>
              <p>Read the information carefully to upload your music.</p>
            </div>
            <ul>
              <li>
                <p>
                  <input
                    type="checkbox"
                    id="test1"
                    name="radio-group"
                    checked
                    class="radio"
                  />
                  <label for="test1"
                    >I recorded this skits and I am authorized to sell it in
                    stores worldwide & collect royalties
                  </label>
                </p>
              </li>
              <li>
                <p>
                  <input
                    type="checkbox"
                    id="test2"
                    name="radio-group"
                    class="radio"
                  />
                  <label for="test2"
                    >I’m not using any other artist’s name in my name without
                    their approval</label
                  >
                </p>
              </li>
              <li>
                <p>
                  <input
                    type="checkbox"
                    id="test3"
                    name="radio-group"
                    class="radio"
                  />
                  <label for="test3"
                    >I have read and agree to the terms of
                    <a href="javscript:;" class="kwot_link"
                      >Kwot agreement.</a
                    ></label
                  >
                </p>
              </li>
            </ul>
          </div>
          <div class="back_continue">
            <div class="back_icon">
              <img src="assets/images/back_vector.png" />
            </div>
            <div class="continue_btn">
              <button>Continue</button>
            </div>
          </div>
          <div class="bottom_steps">
            <div class="steps_count">
              <p>Step 1 of 3</p>
              <button>Continue</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</div>
