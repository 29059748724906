import {Component, ViewEncapsulation} from '@angular/core';

@Component({
  selector: 'frontend-live-show',
  templateUrl: './live-show.component.html',
  styleUrls: ['./live-show.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class LiveShowComponent {
  selectedValue = 1;
  isMultiple = false;
  selectOptions = [
    {id: 1, name: 'Last Week'},
    {id: 2, name: 'Last Month'},
    {id: 3, name: 'Last year'},
  ];
}
