import {Injectable} from '@angular/core';
import {CanActivate, Router} from '@angular/router';
import {map} from 'rxjs';
import {AuthState, getLoggedInUser} from "@frontend/auth-store";
import {select, Store} from "@ngrx/store";
import {LocalstorageService} from "@frontend/app-config";

@Injectable({
  providedIn: 'root'
})
export class VerificationGuard implements CanActivate {

  constructor(
    private router: Router,
    private authStore: Store<AuthState>,
    private ls: LocalstorageService
  ) {
  }

  canActivate() {
    return this.authStore.pipe(select(getLoggedInUser)).pipe(
      map((currentUser: any) => {
        if (!currentUser) {
          this.router.navigate(['/sign-in']);
          return false;
        }
        currentUser = this.ls.updateUserKey('get');
        if (currentUser && currentUser.is_admin_verified === 'PENDING' && currentUser['stdio_status'] !== 3) {
          this.navigateCurrentRoutes(Number(currentUser['stdio_status']));
          return false;
        }
        return true;
      })
    )
  }

  navigateCurrentRoutes(profileStatus: number) {
    if (profileStatus === 0 || profileStatus === 1) {
      this.router.navigate(['/setup', 'type']);
    }
    if (profileStatus === 2) {
      this.router.navigate(['/setup', 'profile']);
    }
    if (profileStatus === 3) {
      this.router.navigate(['/setup', 'verify']);
    }
  }

}
