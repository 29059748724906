<div class="body_bg">
  <div class="show_expand">
    <div class="expand_video">
      <img src="assets/images/expand_img.png" />
    </div>
    <div class="expand_top_bar">
      <span><img src="assets/images/live.svg" /></span>
      <h1>Live show name goes here</h1>
    </div>
    <div class="bottom_bar">
      <div class="row">
        <div class="col-md-8">
          <div class="bottom_live_icon">
            <div class="m_icons">
              <div class="svg_ic">
                <span><img src="assets/images/video.svg" /></span>
              </div>
              <div class="svg_ic">
                <span><img src="assets/images/audio.svg" /></span>
              </div>
              <div class="svg_ic">
                <span><img src="assets/images/settings_2.svg" /></span>
              </div>
              <div class="vido_duration"><h4>00:15:30</h4></div>
            </div>
            <div class="live_end">
              <div
                class="end_live_btn"
                data-bs-toggle="modal"
                data-bs-target="#end_live"
              >
                <button>End live show</button>
              </div>
              <div class="explore_ic">
                <a href="#"><img src="assets/images/expad_active.svg" /></a>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-4"></div>
      </div>
    </div>
    <div class="right_chat_bar">
      <div class="top_user_section">
        <div class="people_joined">
          <div class="people">
            <ul>
              <li><img src="assets/images/avatars_2.png" /></li>
              <li><img src="assets/images/user_photo.png" /></li>
              <li><img src="assets/images/01.png" /></li>
              <li><img src="assets/images/avatars_2.png" /></li>
              <li><img src="assets/images/avatars_2.png" /></li>
              <li><div class="more_people">+15</div></li>
            </ul>
          </div>
          <div class="people_like_main">
            <div class="people_likes">
              <span><img src="assets/images/like_ic.svg" /></span>
              <p>112</p>
            </div>
            <span class="arrow_d" (click)="onClick()"
              ><img src="assets/images/arow_up.svg"
            /></span>
          </div>
        </div>
        <div class="chat_area" *ngIf="visible">
          <p>
            This is a text message from a user and can be as long as the user
            has written
          </p>
          <div class="chat_user">
            <div class="user_details">
              <img src="assets/images/avatars_2.png" />
              <h2>Roy V.</h2>
            </div>
            <p>This is a text message</p>
          </div>
          <div class="chat_user">
            <div class="user_details">
              <img src="assets/images/01.png" />
              <h2>You</h2>
            </div>
            <p>
              This is a text message from you to users are watching your show
            </p>
          </div>
          <div class="chat_user">
            <div class="user_details">
              <img src="assets/images/user_photo.png" />
              <h2>Peter P.</h2>
              <img src="assets/images/thumb_down.svg" class="last_ic" />
            </div>
          </div>

          <div class="chat_user">
            <div class="user_details">
              <img src="assets/images/user_photo.png" />
              <h2>Susan R.</h2>
            </div>
            <p>
              This is a text message from a user and can be as long as the user
              has written
            </p>
          </div>

          <div class="chat_user">
            <div class="user_details">
              <img src="assets/images/user_photo.png" />
              <h2>Steven U.</h2>
              <img src="assets/images/like_ic.svg" class="last_ic" />
            </div>
          </div>
        </div>
        <div class="write_input" *ngIf="visible">
          <input type="text" placeholder="Write here" />
          <span><img src="assets/images/send.svg" /></span>
        </div>
      </div>
    </div>
  </div>
</div>
