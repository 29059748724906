<div class="body_bg">
  <div class="main_wrapper">
    <frontend-verify-header></frontend-verify-header>
    <frontend-side-bar></frontend-side-bar>

    <div class="main_content">
      <div class="row">
        <div class="col-12">
          <div class="live_show_heading">
            <span><img src="assets/images/Live_show_svg.svg" /></span>
            <h1>Live show name goes here</h1>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-md-12 col-lg-8">
          <div class="show_live_main">
            <div class="live_show_img">
              <img src="assets/images/live_show_img.png" />
            </div>
            <!-- <div class="music_containers">
              <div class="music_img">
                <img src="assets/images/unsplashimg.png" />
              </div>
              <div class="show_main">
                <div class="m_timer">
                  <div class="timer">
                    <div class="time">
                      <span>0</span>
                      <span>0</span>
                    </div>
                    <p>DAYS</p>
                  </div>
                  <div class="dots">:</div>
                  <div class="timer">
                    <div class="time">
                      <span>0</span>
                      <span>0</span>
                    </div>
                    <p>HOURS</p>
                  </div>
                  <div class="dots">:</div>
                  <div class="timer">
                    <div class="time">
                      <span>0</span>
                      <span>0</span>
                    </div>
                    <p>MINUTES</p>
                  </div>
                </div>
                <div class="timer_des"><p>Your show will star soon. Do you have all you need?</p></div>
                <div class="start_btn"><button>Start</button></div>
              </div>
          </div> -->

            <div class="bottom_live_icon">
              <div class="m_icons">
                <div
                  class="svg_ic"
                  data-bs-toggle="modal"
                  data-bs-target="#pause_video"
                >
                  <span><img src="assets/images/video.svg" /></span>
                </div>
                <div
                  class="svg_ic"
                  data-bs-toggle="modal"
                  data-bs-target="#exampleModal"
                >
                  <span><img src="assets/images/audio.svg" /></span>
                </div>
                <div
                  class="svg_ic"
                  data-bs-toggle="modal"
                  data-bs-target="#setting_ic"
                >
                  <span><img src="assets/images/settings_2.svg" /></span>
                </div>
                <div class="vido_duration"><h4>00:15:30</h4></div>
              </div>
              <div class="live_end">
                <div
                  class="end_live_btn"
                  data-bs-toggle="modal"
                  data-bs-target="#end_live"
                >
                  <button>End live show</button>
                </div>
                <div class="explore_ic">
                  <a href="#"><img src="assets/images/expad_active.svg" /></a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-12 col-lg-4">
          <div class="chat_view">
            <div class="people_joined">
              <!-- <div class="d-flex">
                  <span><img src="assets/images/people_group.svg"/></span>
                  <p><b>5</b> people joined this show</p>
                </div> -->
              <div class="people">
                <ul>
                  <li><img src="assets/images/avatars_2.png" /></li>
                  <li><img src="assets/images/user_photo.png" /></li>
                  <li><img src="assets/images/01.png" /></li>
                  <li><img src="assets/images/avatars_2.png" /></li>
                  <li><img src="assets/images/avatars_2.png" /></li>
                  <li><div class="more_people">+15</div></li>
                </ul>
              </div>
              <div class="people_likes">
                <span><img src="assets/images/like_ic.svg" /></span>
                <p>112</p>
              </div>
            </div>
            <div class="chat_area">
              <p>
                This is a text message from a user and can be as long as the
                user has written
              </p>
              <div class="chat_user">
                <div class="user_details">
                  <img src="assets/images/avatars_2.png" />
                  <h2>Roy V.</h2>
                </div>
                <p>This is a text message</p>
              </div>
              <div class="chat_user">
                <div class="user_details">
                  <img src="assets/images/01.png" />
                  <h2>You</h2>
                </div>
                <p>
                  This is a text message from you to users are watching your
                  show
                </p>
              </div>
              <div class="chat_user">
                <div class="user_details">
                  <img src="assets/images/user_photo.png" />
                  <h2>Peter P.</h2>
                  <img src="assets/images/thumb_down.svg" class="last_ic" />
                </div>
              </div>

              <div class="chat_user">
                <div class="user_details">
                  <img src="assets/images/user_photo.png" />
                  <h2>Susan R.</h2>
                </div>
                <p>
                  This is a text message from a user and can be as long as the
                  user has written
                </p>
              </div>

              <div class="chat_user">
                <div class="user_details">
                  <img src="assets/images/user_photo.png" />
                  <h2>Steven U.</h2>
                  <img src="assets/images/like_ic.svg" class="last_ic" />
                </div>
              </div>
            </div>

            <div class="write_input">
              <input type="text" placeholder="Write here" />
              <span><img src="assets/images/send.svg" /></span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="mobile_live_show">
      <div class="video_sections">
        <img src="assets/images/unsplash_v.png" />
      </div>
      <div class="top_mobile">
        <div class="top_mobile_icon">
          <span><img src="assets/images/back_svg.svg" /></span>
          <span><img src="assets/images/live.svg" /></span>
        </div>
        <div class="top_mobile_heading">
          <h1>Live show name goes here a...</h1>
        </div>
      </div>
      <p class="time_count">00:15:30</p>
      <div class="bottom_mobile_screen">
        <div class="chat_section_main">
          <div class="chats">
            <div class="user_like">
              <div class="icons_c">
                <img src="assets/images/people_group.svg" />
                <span>56</span>
              </div>
              <div class="icons_c">
                <img src="assets/images/like_ic.svg" />
                <span>45</span>
              </div>
            </div>
            <ul>
              <li class="chat_user">
                <div class="user_details">
                  <img src="assets/images/01.png" />
                  <h2>Susan R.</h2>
                </div>
                <p>
                  This is a text message from you to users are watching your
                  show
                </p>
              </li>
              <li class="chat_user">
                <div class="user_details">
                  <img src="assets/images/01.png" />
                  <h2>You</h2>
                  <img src="assets/images/like_ic.svg" class="last_ic" />
                </div>
              </li>
              <li class="chat_user">
                <div class="user_details">
                  <img src="assets/images/01.png" />
                  <h2>You</h2>
                </div>
                <p>
                  This is a text message from you to users are watching your
                  show
                </p>
              </li>
            </ul>
          </div>
          <div class="chat_options">
            <ul>
              <li>
                <img src="assets/images/video.svg" />
              </li>
              <li>
                <img src="assets/images/audio.svg" />
              </li>
              <li>
                <img src="assets/images/chat_2.svg" />
              </li>
              <li>
                <img src="assets/images/more_.svg" />
              </li>
            </ul>
          </div>
        </div>
        <div class="msg_field_send">
          <input type="text" placeholder="Write here" />
          <span> <img src="assets/images/send.svg" /></span>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Allow microphone Modal -->
<div
  class="modal fade"
  id="end_live"
  tabindex="-1"
  aria-labelledby="exampleModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog modal-dialog-centered model_mobile">
    <div class="modal-content">
      <div class="modal-body main_content_model">
        <button
          type="button"
          class="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
        >
          <img src="assets/images/cros_pop.svg" />
        </button>
        <div class="radio_img">
          <img src="assets/images/end_live.png" />
        </div>
        <div class="mute_music_content">
          <h1>Are you sure you want to end live show?</h1>
          <p>Viewers will be surprised!</p>
          <a href="javascript:;">End live show</a>
          <div class="allow_btn">
            <button>Cancel</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
