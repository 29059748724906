import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ArtistState } from './artist.reducer';

const getState = createFeatureSelector<ArtistState>('artist');

export const getError = createSelector(
  getState,
  (state: ArtistState) => state?.error
);
export const getSuccess = createSelector(
  getState,
  (state: ArtistState) => state?.success
);

export const getArtistDashboard = createSelector(
  getState,
  (state: ArtistState) => state.dashboard
);

export const getLiveShowSuccess = createSelector(
  getState,
  (state: ArtistState) => state?.liveShows
);

export const getEventSuccess = createSelector(
  getState,
  (state: ArtistState) => state?.fanClub
);

export const getArtist = createSelector(
  getState,
  (state: ArtistState) => state?.artist
);
export const getNameAvailability = createSelector(
  getState,
  (state: ArtistState) => state?.isNameAvailable
);
export const getPodcasts = createSelector(
  getState,
  (state: ArtistState) => state?.podcasts
);
export const getPodcastEpisodes = createSelector(
  getState,
  (state: ArtistState) => state?.episodes
);
export const getAllCategories = createSelector(
  getState,
  (state: ArtistState) => state?.categories
);
export const getPodcast = createSelector(
  getState,
  (state: ArtistState) => state?.podcast
);
export const getEpisode = createSelector(
  getState,
  (state: ArtistState) => state?.episode
);
export const getSkits = createSelector(
  getState,
  (state: ArtistState) => state?.skits
);
export const getSkit = createSelector(
  getState,
  (state: ArtistState) => state?.skit
);
export const getMusics = createSelector(
  getState,
  (state: ArtistState) => state?.musics
);
export const getMusicTracks = createSelector(
  getState,
  (state: ArtistState) => state?.tracks
);
export const getAllGenres = createSelector(
  getState,
  (state: ArtistState) => state?.genres
);
export const getMusic = createSelector(
  getState,
  (state: ArtistState) => state?.music
);
export const getTrack = createSelector(
  getState,
  (state: ArtistState) => state?.track
);
export const getAllBankDetail = createSelector(
  getState,
  (state: ArtistState) => state.bankDetails
);
export const getBankDetail = createSelector(
  getState,
  (state: ArtistState) => state.bankDetail
);
export const GetAllEventList = createSelector(
  getState,
  (state: ArtistState) => state.events
);
export const getpostedExclusiveData = createSelector(
  getState,
  (state: ArtistState) => state.exclusiveContent
);
export const getFanClub = createSelector(
  getState,
  (state: ArtistState) => state.fanClub
);
export const getDiscountsRes = createSelector(
  getState,
  (state: ArtistState) => state.discounts
);
export const getLiveShowsRes = createSelector(
  getState,
  (state: ArtistState) => state.liveShows
);
export const getAllPlans = createSelector(
  getState,
  (state: ArtistState) => state.plans
);
export const getExclusiveData = createSelector(
  getState,
  (state: ArtistState) => state.contentlist
);
export const getLiveShowsData = createSelector(
  getState,
  (state: ArtistState) => state.showslist
);
export const getDiscountsData = createSelector(
  getState,
  (state: ArtistState) => state.discountlist
);
export const getArtistAllPlans = createSelector(
  getState,
  (state: ArtistState) => state.artistplans
);
export const getUrlsList = createSelector(
  getState,
  (state: ArtistState) => state.urlslist
);
export const getBillingDetailsData = createSelector(
  getState,
  (state: ArtistState) => state.billingDetails
);

export const generateRtmToken = createSelector(
  getState,
  (state: ArtistState) => state.rtmToken
);

export const getAllRadioStations = createSelector(
  getState,
  (state: ArtistState) => state?.radio_stations
);

export const getRadioStation = createSelector(
  getState,
  (state: ArtistState) => state?.radio_station
);

