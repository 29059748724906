import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpEventType, HttpHeaders } from '@angular/common/http';
import { LoaderService } from './loader.service';
import { APP_CONFIG } from '../index';
// import { MediaEnum } from "../../data-models/src/lib/consts/enums";

@Injectable({
  providedIn: 'root',
})
export class UploadImageService {
  ApiUrl: string;

  constructor(
    private loaderService: LoaderService,
    private http: HttpClient,
    @Inject(APP_CONFIG) private appConfig: any
  ) {
    this.ApiUrl = `${this.appConfig.apiUrl}`;
  }

  getAwsSignedUrl(
    file_name: any,
    content_type: any,
    folder_path: any,
    user_id: any
  ) {
    const headers = new HttpHeaders({ 'Skip-auth': 'true' });
    return this.http.get(`${this.ApiUrl}upload/get-signed-url`, {
      params: {
        file_name,
        content_type,
        folder_path,
        user_id,
      },
      headers,
    });
  }

  uploadFileToSignedUrl(url: any, fileData: any) {
    const headers = new HttpHeaders({
      'Skip-auth': 'true',
      'Content-Type': fileData.type,
    });
    return this.http.put(url, fileData, {
      headers,
      reportProgress: true,
      observe: 'events',
    });
  }

  getSingedUrl(fileName: any, contentType: any, folderPath: any, userId: any) {
    this.loaderService.show();

    return new Promise((resolve, reject) => {
      return this.getAwsSignedUrl(
        fileName,
        contentType,
        folderPath,
        userId
      ).subscribe(
        (resp: any) => {
          resolve(resp.data);
          // this.loaderService.hide();
        },

        (error) => {
          reject(error);
          this.loaderService.hide();
        }
      );
    });
  }

  async uploadImageAndGetUrl({
    file_name,
    content_type,
    folder_path,
    user_id,
    file,
  }: any) {
    let data: any = await this.getSingedUrl(
      file_name,
      content_type,
      folder_path,
      user_id
    );
    return await this.uploadImage(data, file);
  }

  uploadImage(data: any, file: any) {
    return new Promise((resolve, reject) => {
      return this.uploadFileToSignedUrl(data.url, file).subscribe(
        (resp) => {
          if (resp.type === HttpEventType.UploadProgress) {
            const percentDone = Math.round((100 * resp.loaded) / resp.total);
            this.loaderService.updateProgress(percentDone);
          }
          if (resp.type === HttpEventType.Response) {
            this.loaderService.hide();
            resolve(data.url.split('?')[0]);
          }
        },
        (error) => reject(error)
      );
    });
  }

  // getCompressedMedias(originalUrl: any, mediaType: any, type: any) {
  //   if (!originalUrl) {
  //     return '';
  //   }
  //   // skit videos
  //   if (mediaType === MediaEnum.Video) {
  //     if (type === 'skit' && originalUrl.includes(type) && originalUrl.includes("video")) {
  //       return originalUrl.replace("/video/", "/video/compress/");
  //     } else if (type === 'fan-club') {
  //       // fan-club videos
  //       if (originalUrl.includes("thumbnail")) {
  //         return originalUrl.replace("/thumbnail/", "/fan-club/compress/");
  //       }
  //       return originalUrl.replace("/fan-club/", "/fan-club/compress/");
  //     }
  //   }
  //
  //   if (mediaType === MediaEnum.Image) {
  //     const urlParts = originalUrl.split('/');
  //     const name = urlParts.pop();
  //     return urlParts.join('/') + '/compress/' + name;
  //   }
  //
  //   return originalUrl;
  // }
}
