import {ChangeDetectionStrategy, Component, OnInit} from '@angular/core';
import {Observable} from 'rxjs';
import {
  RouteConfigLoadEnd,
  RouteConfigLoadStart,
  Router,
} from '@angular/router';
import {LoaderService} from '@frontend/app-config';

@Component({
  selector: 'frontend-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent implements OnInit {
  title = 'artist';
  loader$: Observable<boolean>;
  uploader$: Observable<boolean>;
  progress$: Observable<number>;
  loadingRouteConfig = false;
  currentRoute: string;
  hideSideBarAndHeader = [
    '/setup/type',
    '/setup/profile',
    '/setup/verify',
    '/setup/congratulations',
    '/sign-in',
    '/register',
    '/forgot-password',
    '/reset-password',
    '/fan-club/create']
  url: string;

  constructor(private router: Router, private loaderService: LoaderService
  ) {
    this.router.events.subscribe((event: any) => {
      if (event.url) {
        const url = event.url.split('/').filter((t: any) => t);
        if (url[0]) {
          this.currentRoute = url[0];
          this.url = `/${url[0]}`
        }
      }

      if (event instanceof RouteConfigLoadStart) {
        this.loadingRouteConfig = true;
      } else if (event instanceof RouteConfigLoadEnd) {
        this.loadingRouteConfig = false;
      }
    });

  }

  ngOnInit() {
    this.loader$ = this.loaderService.loader$;
    this.uploader$ = this.loaderService.uploader$;
    this.progress$ = this.loaderService.progress$;
  }
}
