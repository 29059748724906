import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'frontend-live-skit',
  templateUrl: './live-skit.component.html',
  styleUrls: ['./live-skit.component.scss'],
})
export class LiveSkitComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
